import { useEffect, useState } from 'react';
import { useApolloClient } from '@apollo/client';
import { Button, Form, Input } from 'reactstrap';
import { errorMsg, successMsg } from '../../cache/vars';
import { DECLINE_SERVICE, GET_AVAILABLE_FILTERS_FOR_PACKAGE_INFO, SAVE_STAGE_ONE_CARD } from '../../queries/vip-workflow';
import { SERVICE_STATE } from '../../utils/constants';
import StagedCardLayout from '../StagedCard/StagedCardLayout/StagedCardLayout';
import CardDetails from '../StagedCard/CardDetails/CardDetails';
import GooglePlaceAutocompleteInput from '../GooglePlaceAutocomplete/GooglePlaceAutocomplete';
import CardDeclineBtn from '../StagedCard/CardDeclineBtn/CardDeclineBtn';
import TransferTypeButtons from '../Buttons/TransferTypeButtons';
import CardGoogleMap from '../StagedCard/CardGoogleMap/CardGoogleMap';
import CardGuaranteed from '../StagedCard/CardGuaranteed/CardGuaranteed';
import CardAction from '../StagedCard/CardAction/CardAction';
import reservationIcon from '../../assets/img/icons/cards/reservation-icon.svg';
import flagIcon from '../../assets/img/icons/cards/flag-icon.svg';
import partyPooperIcon from '../../assets/img/icons/cards/party-pooper-icon.svg';
import planeIcon from '../../assets/img/icons/cards/plane-icon.svg';
import helpIcon from '../../assets/img/icons/cards/help-icon.svg';
import carIcon from '../../assets/img/icons/cards/car-icon.svg';
import { generateMailtoLink } from '../../utils/services';

import './vegasLimoTransferGroupCardNew.scss';
import moment from 'moment';

type VegasLimoTransferGroupCardNewProps = {
  cart: any;
  datetime: any | null;
  time: string;
  isMissingInfo: boolean;
  isPublished: boolean;
  isServiceDeclined: boolean;
  serviceDeclined: string;
  packageDetailsId: number;
  setPackageOrderWithDetails: (data: any) => void;
  decryptedInvoiceId: any;
  setMissingInfoCards: any;
  cardDescription: string;
  cardGroup: string;
};

const VegasLimoTransferGroupCardNew = ({
  cart,
  datetime,
  time,
  isMissingInfo,
  isPublished,
  isServiceDeclined,
  serviceDeclined,
  packageDetailsId,
  setPackageOrderWithDetails,
  decryptedInvoiceId,
  setMissingInfoCards,
  cardDescription,
  cardGroup,
}: VegasLimoTransferGroupCardNewProps) => {
  const client = useApolloClient();
  
  const [emailLink, setEmailLink] = useState("");
  
  const [vegasLimoTransferType, setVegasLimoTransferType] = useState<"transfer1" | "transfer2">(
    "transfer1",
  );
  
  const [transferId, setTransferId] = useState<"1" | "2">("1");
  
  const [isTransfer, setIsTransfer] = useState(true);

  const [cardState, setCardState] = useState<{ packageDetailsId: number; cartData: any }>({
    packageDetailsId,
    cartData: {
      transfer1: {
        transferId: "1",
      },
      transfer2: {
        transferId: "2",
      },
    },
  });

  const [isFieldsValid, setIsFieldsValid] = useState({
    transfer1: {
      contactName1: true,
      contactNumber1: true,
      pickUpName1: true,
      pickUpAddress1: true,
    },
    transfer2: {
      contactName2: true,
      contactNumber2: true,
      dropOffName2: true,
      dropOffAddress2: true,
    },
  });
  
  const [cartJsonData, setCartJsonData] = useState<any>({});
  
  const [isSubmitBtnDisabled, setIsSubmitBtnDisabled] = useState(true);
  
  const handleDeclineClick = async () => {
    if (window.confirm("Are you sure you want to decline this service?")) {
      const { data } = await client.mutate({
        mutation: DECLINE_SERVICE,
        variables: { packageDetailsId: packageDetailsId },
      });

      if (data?.declineService) {
        setPackageOrderWithDetails((prev: any) => {
          return {
            ...prev,
            orderDetails: prev.orderDetails.map((item: any) => {
              return item.packageDetailsId === packageDetailsId
                ? {
                    ...item,
                    cartDetails: {
                      ...item.cartDetails,
                      cartId: data?.declineService,
                      isServiceDeclined: SERVICE_STATE.DECLINED,
                    },
                  }
                : item;
            }),
          };
        });
        successMsg("Service Declined");
      }
    }
  };
  
  const parsedCardDate = JSON.parse(cart.cartDetails.cartJsonData);
  
  useEffect(() => {
    const vegasLimoTransfer1RequiredFields = [
      "contactName1",
      "contactNumber1",
      "pickUpName1",
      "pickUpAddress1",
    ];

    const vegasLimoTransfer2RequiredFields = [
      "contactName2",
      "contactNumber2",
      "dropOffName2",
      "dropOffAddress2",
    ];

    const isTransfer1RequiredFieldsEmpty = vegasLimoTransfer1RequiredFields.some((el) => {
      return !cardState.cartData.transfer1[el];
    });
    
    const isTransfer2RequiredFieldsEmpty = vegasLimoTransfer2RequiredFields.some((el) => {
      return !cardState.cartData.transfer2[el];
    });

    setIsSubmitBtnDisabled(isTransfer1RequiredFieldsEmpty || isTransfer2RequiredFieldsEmpty);
  }, [cardState.cartData]);
  
  useEffect(() => {
    const data = JSON.parse(cart.cartDetails.cartJsonData);

    // if (data && data[vegasLimoTransferType]) {
    //   setCartJsonData(data[vegasLimoTransferType]);
    // } else {
    //   setCartJsonData(data);
    // }
    
    if (vegasLimoTransferType === 'transfer1') {
      setTransferId('1');
    } else if (vegasLimoTransferType === 'transfer2') {
      setTransferId('2');
    }
    
    if (data && !data[vegasLimoTransferType]) {
      setTransferId(data.transferId);
    }

    if (data && data[vegasLimoTransferType]) {
      setCartJsonData(data[vegasLimoTransferType]);
    } else {
      setCartJsonData(data);
      setIsTransfer(false);
    }
  }, [vegasLimoTransferType, cart.cartDetails.cartJsonData]);
  
  useEffect(() => {
    generateMailtoLink(client, window.location.pathname, setEmailLink);
  }, [client]);
  
  return (
    <StagedCardLayout
      cardIcon={partyPooperIcon} 
      cardTitle={cart?.cartDetails?.cardTitle || "Vegas Limo Transfer"}
      datetime={datetime}
      time={time}
      isPublished={isPublished} 
      isServiceDeclined={isServiceDeclined}
      serviceDeclined={serviceDeclined}
      isMissingInfo={isMissingInfo}
    >
      <>
        {isPublished &&
          <>
            <div className="mb-4"> 
              <TransferTypeButtons
                vegasLimoTransferType={vegasLimoTransferType}
                setVegasLimoTransferType={setVegasLimoTransferType}
                transfer1Date={parsedCardDate?.transfer1?.pickUp1 || ''}
                transfer2Date={parsedCardDate?.transfer2?.pickUp2 || ''}
              />
            </div>
          
            <CardDetails
              cardIcon={carIcon}
              cardTitle="Vendor Name"
              cardSubtitle={cartJsonData[`vendorName${cartJsonData.transferId}`]}
            >
              <div className="vegas-limo-transfer-card-content">
                {cartJsonData[`vendorPhone${cartJsonData.transferId}`] &&
                  <>
                    <div className="card-content-column">
                      <p>Vendor Phone</p>
                    </div>
                    <div className="card-content-column">
                      <p>{cartJsonData[`vendorPhone${cartJsonData.transferId}`]}</p>
                    </div>
                  </>
                }
              </div>
            </CardDetails>
            
            <div className="divider" />
            
            <CardDetails
              cardIcon={carIcon}
              cardTitle="Pick Up At"
              cardSubtitle={moment(cartJsonData[`pickUp${cartJsonData.transferId}`]).format('MMMM Do YYYY, h:mm a')} 
            >
              <div className="vegas-limo-transfer-card-content">
                {cartJsonData[`pickUpName${cartJsonData.transferId}`] &&
                  <>
                    <div className="card-content-column">
                      <p>Pick Up Name</p>
                    </div>
                    <div className="card-content-column">
                      <p>{cartJsonData[`pickUpName${cartJsonData.transferId}`]}</p>
                    </div>
                  </>
                }
              </div>

              <div className="vegas-limo-transfer-card-content">
                {cartJsonData[`pickUpAddress${cartJsonData.transferId}`] &&
                  <>
                    <div className="card-content-column">
                      <p>Pick Up Address</p>
                    </div>
                    <div className="card-content-column">
                      <p>{cartJsonData[`pickUpAddress${cartJsonData.transferId}`]}</p>
                    </div>
                  </>
                }
              </div>
              <CardGoogleMap address={cartJsonData[`pickUpAddress${cartJsonData.transferId}`]} className="pl-4" />
              
              <div className="vegas-limo-transfer-card-content">
                {cartJsonData[`contactName${cartJsonData.transferId}`] &&
                  <>
                    <div className="card-content-column">
                      <p>Contact Name</p>
                    </div>
                    <div className="card-content-column">
                      <p>{cartJsonData[`contactName${cartJsonData.transferId}`]}</p>
                    </div>
                  </>
                }
              </div>
              
              <div className="vegas-limo-transfer-card-content">
                {cartJsonData[`contactNumber${cartJsonData.transferId}`] &&
                  <>
                    <div className="card-content-column">
                      <p>Contact Number</p>
                    </div>
                    <div className="card-content-column">
                      <p>{cartJsonData[`contactNumber${cartJsonData.transferId}`]}</p>
                    </div>
                  </>
                }
              </div>
            </CardDetails>
            
            <div className="divider" />
            
            <CardDetails
              cardIcon={carIcon}
              cardTitle="Transporting To"
              cardSubtitle=""
            >
              <div className="vegas-limo-transfer-card-content">
                {cartJsonData[`dropOffName${cartJsonData.transferId}`] &&
                  <>
                    <div className="card-content-column">
                      <p>Drop Off Name</p>
                    </div>
                    <div className="card-content-column">
                      <p>{cartJsonData[`dropOffName${cartJsonData.transferId}`]}</p>
                    </div>
                  </>
                }
              </div>

              <div className="vegas-limo-transfer-card-content">
                {cartJsonData[`dropOffAddress${cartJsonData.transferId}`] &&
                  <>
                    <div className="card-content-column">
                      <p>Drop Off Address</p>
                    </div>
                    <div className="card-content-column">
                      <p>{cartJsonData[`dropOffAddress${cartJsonData.transferId}`]}</p>
                    </div>
                  </>
                }
              </div>
              <CardGoogleMap address={cartJsonData[`dropOffAddress${cartJsonData.transferId}`]} className="pl-4" />
              
              <div className="vegas-limo-transfer-card-content">
                {cartJsonData[`celebration${cartJsonData.transferId}`] &&
                  <>
                    <div className="card-content-column">
                      <p>Celebration</p>
                    </div>
                    <div className="card-content-column">
                      <p>{cartJsonData[`celebration${cartJsonData.transferId}`]}</p>
                    </div>
                  </>
                }
              </div>
            </CardDetails>
            
            <div className="divider" />
            
            <CardDetails
              cardIcon={flagIcon}
              cardTitle="Additional Notes"
              cardDescription={cartJsonData[`notes${cartJsonData.transferId}`] || ""}
            />
          </>
        }

        {!isPublished && isMissingInfo && (
          <>
            <div className="mb-4"> 
              <TransferTypeButtons
                vegasLimoTransferType={vegasLimoTransferType}
                setVegasLimoTransferType={setVegasLimoTransferType}
                transfer1Date={cartJsonData?.transfer1?.pickUp1 || ''}
                transfer2Date={cartJsonData?.transfer2?.pickUp2 || ''}
              />
            </div>

            <CardDetails
              isEditable={true}
              cardIcon={planeIcon}
              cardTitle="Arrival Details"
              cardSubtitle="Please provide your info"
            >
              <>
                <p className="mt-4 mr-4 mb-0 ml-2">
                  Provide us with your arrival flight information. Transfers are reserved in a vehicle accommodating the size of your party associated to the package. Special vehicle types and additional stops will require an additional charge.
                </p>
                
                <div className="divider" />
                
                <div className="mb-4">
                  <p>Contact Details</p>
                  <p className="required-note">* These fields are required.</p>
                </div>
                
                <Form
                  onSubmit={async (e) => {
                    e.preventDefault();
                    const vegasLimoTransfer1RequiredFields = [
                      "pickUpName1",
                      "pickUpAddress1",
                      "contactName1",
                      "contactNumber1",
                    ];

                    const vegasLimoTransfer2RequiredFields = [
                      "dropOffName2",
                      "dropOffAddress2",
                      "contactName2",
                      "contactNumber2",
                    ];

                    const isTransfer1RequiredFieldsEmpty = vegasLimoTransfer1RequiredFields.some((el) => {
                      return !cardState.cartData.transfer1[el];
                    });

                    const isTransfer2RequiredFieldsEmpty = vegasLimoTransfer2RequiredFields.some((el) => {
                      return !cardState.cartData.transfer2[el];
                    });

                    if (isTransfer1RequiredFieldsEmpty || isTransfer2RequiredFieldsEmpty) {
                      setIsFieldsValid({
                        transfer1: {
                          contactName1: !!cardState.cartData.transfer1.contactName1,
                          contactNumber1: !!cardState.cartData.transfer1.contactNumber1,
                          pickUpName1: !!cardState.cartData.transfer1.pickUpName1,
                          pickUpAddress1: !!cardState.cartData.transfer1.pickUpAddress1,
                        },
                        transfer2: {
                          contactName2: !!cardState.cartData.transfer2.contactName2,
                          contactNumber2: !!cardState.cartData.transfer2.contactNumber2,
                          dropOffName2: !!cardState.cartData.transfer2.dropOffName2,
                          dropOffAddress2: !!cardState.cartData.transfer2.dropOffAddress2,
                        },
                      });

                      errorMsg("Required Fields Should Not Be Empty");

                      return;
                    }

                    const { data } = await client.mutate({
                      mutation: SAVE_STAGE_ONE_CARD,
                      variables: { editCartDetailsInput: cardState },
                    });

                    const filtersData = await client.query({
                      query: GET_AVAILABLE_FILTERS_FOR_PACKAGE_INFO,
                      variables: { invoiceId: decryptedInvoiceId },
                      fetchPolicy: "no-cache",
                    });

                    if (data) {
                      setPackageOrderWithDetails((prev: any) => {
                        return {
                          ...prev,
                          orderDetails: [
                            ...prev.orderDetails.map((cart: any) => {
                              return cart.packageDetailsId === packageDetailsId
                                ? {
                                    ...cart,
                                    cartDetails: {
                                      ...cart.cartDetails,
                                      cardTitle: data?.saveStageOneCard.cardTitle,
                                      cartId: data?.saveStageOneCard.cartId,
                                      cartJsonData: data?.saveStageOneCard.cartJsonData,
                                      isPublished: data?.saveStageOneCard.isPublished,
                                      packageDetailsId: data?.saveStageOneCard.packageDetailsId,
                                    },
                                  }
                                : cart;
                            }),
                          ],
                        };
                      });
                      
                      setMissingInfoCards(
                        filtersData.data?.getAvailableFiltersForPackageInfo.showMissingInfoFor,
                      );

                      successMsg("Saved");
                    }
                  }}
                >
                  {vegasLimoTransferType === "transfer1" ? (
                    <div className="inputs-container">
                      <div className="input-container">
                        <label className={!isFieldsValid.transfer1.contactName1 ? "label-invalid" : ""}>
                          Contact Name
                        </label>
                        <div className="input-box">
                          <Input
                            className={!isFieldsValid.transfer1.contactName1 ? "input-invalid" : ""}
                            type="text"
                            placeholder="Contact Name"
                            name="contactName1"
                            invalid={!isFieldsValid.transfer1.contactName1}
                            onChange={(e) => {
                              setCardState((prev: any) => {
                                return {
                                  ...prev,
                                  cartData: {
                                    ...prev.cartData,
                                    transfer1: {
                                      ...prev.cartData.transfer1,
                                      [e.target.name]: e.target.value,
                                    },
                                  },
                                };
                              });
                            }}
                            value={cardState.cartData.transfer1.contactName1 || ""}
                          />
                          <span></span>
                        </div>
                      </div>
                      <div className="input-container">
                        <label className={!isFieldsValid.transfer1.contactNumber1 ? "label-invalid" : ""}>
                          Contact Number
                        </label>
                        <div className="input-box">
                          <Input
                            className={!isFieldsValid.transfer1.contactNumber1 ? "input-invalid" : ""}
                            type="text"
                            placeholder="Contact Number"
                            name="contactNumber1"
                            invalid={!isFieldsValid.transfer1.contactNumber1}
                            onChange={(e) => {
                              setCardState((prev: any) => {
                                return {
                                  ...prev,
                                  cartData: {
                                    ...prev.cartData,
                                    transfer1: {
                                      ...prev.cartData.transfer1,
                                      [e.target.name]: e.target.value,
                                    },
                                  },
                                };
                              });
                            }}
                            value={cardState.cartData.transfer1.contactNumber1 || ""}
                          />
                          <span></span>
                        </div>
                      </div>{" "}
                      <div className="input-container">
                        <label className={!isFieldsValid.transfer1.pickUpName1 ? "label-invalid" : ""}>Pick-up Name</label>
                        <div className="input-box">
                          <Input
                            className={!isFieldsValid.transfer1.pickUpName1 ? "input-invalid" : ""}
                            type="text"
                            placeholder="Pick-up Name"
                            name="pickUpName1"
                            invalid={!isFieldsValid.transfer1.pickUpName1}
                            onChange={(e) => {
                              setCardState((prev: any) => {
                                return {
                                  ...prev,
                                  cartData: {
                                    ...prev.cartData,
                                    transfer1: {
                                      ...prev.cartData.transfer1,
                                      [e.target.name]: e.target.value,
                                    },
                                  },
                                };
                              });
                            }}
                            value={cardState.cartData.transfer1.pickUpName1 || ""}
                          />
                          <span></span>
                        </div>
                      </div>{" "}
                      <div className="input-container">
                        <label className={!isFieldsValid.transfer1.pickUpAddress1 ? "label-invalid": ""}>Pick-up Address</label>
                        <div className="input-box">
                          <GooglePlaceAutocompleteInput
                            cardState={cardState}
                            setCardState={setCardState}
                            placeName={"pickUpAddress1"}
                            placeValueName={"pickUpPlaceValue"}
                            placeLinkName={"pickUpPlaceLink"}
                            cardStateField={vegasLimoTransferType}
                          />
                          <span></span>
                        </div>
                      </div>{" "}
                      <div className="input-container">
                        <label>Celebration</label>
                        <div className="input-box">
                          <Input
                            type="text"
                            placeholder="Are you celebrating a special occasion?"
                            name="celebration1"
                            onChange={(e) => {
                              setCardState((prev: any) => {
                                return {
                                  ...prev,
                                  cartData: {
                                    ...prev.cartData,
                                    transfer1: {
                                      ...prev.cartData.transfer1,
                                      [e.target.name]: e.target.value,
                                    },
                                  },
                                };
                              });
                            }}
                            value={cardState.cartData.transfer1.celebration1 || ""}
                          />
                          <span></span>
                        </div>
                      </div>{" "}
                    </div>
                  ) : (
                    <div className="inputs-container">
                      <div className="input-container">
                        <label className={!isFieldsValid.transfer2.contactName2 ? "label-invalid" : ""}>
                          Contact Name
                        </label>
                        <div className="input-box">
                          <Input
                            className={!isFieldsValid.transfer2.contactName2 ? "input-invalid" : ""}
                            type="text"
                            placeholder="Contact Name"
                            name="contactName2"
                            invalid={!isFieldsValid.transfer2.contactName2}
                            onChange={(e) => {
                              setCardState((prev: any) => {
                                return {
                                  ...prev,
                                  cartData: {
                                    ...prev.cartData,
                                    transfer2: {
                                      ...prev.cartData.transfer2,
                                      [e.target.name]: e.target.value,
                                    },
                                  },
                                };
                              });
                            }}
                            value={cardState.cartData.transfer2.contactName2 || ""}
                          />
                          <span></span>
                        </div>
                      </div>
                      <div className="input-container">
                        <label className={!isFieldsValid.transfer2.contactNumber2 ? "label-invalid" : ""}>
                          Contact Number
                        </label>
                        <div className="input-box">
                          <Input
                            className={!isFieldsValid.transfer2.contactNumber2 ? "input-invalid" : ""}
                            type="text"
                            placeholder="Contact Number"
                            name="contactNumber2"
                            invalid={!isFieldsValid.transfer2.contactNumber2}
                            onChange={(e) => {
                              setCardState((prev: any) => {
                                return {
                                  ...prev,
                                  cartData: {
                                    ...prev.cartData,
                                    transfer2: {
                                      ...prev.cartData.transfer2,
                                      [e.target.name]: e.target.value,
                                    },
                                  },
                                };
                              });
                            }}
                            value={cardState.cartData.transfer2.contactNumber2 || ""}
                          />
                          <span></span>
                        </div>
                      </div>{" "}
                      <div className="input-container">
                        <label className={!isFieldsValid.transfer2.dropOffName2 ? "label-invalid": ""}>Drop-off Name</label>
                        <div className="input-box">
                          <Input
                            className={!isFieldsValid.transfer2.dropOffName2 ? "input-invalid" : ""}
                            type="text"
                            placeholder="Drop-off Name"
                            name="dropOffName2"
                            invalid={!isFieldsValid.transfer2.dropOffName2}
                            onChange={(e) => {
                              setCardState((prev: any) => {
                                return {
                                  ...prev,
                                  cartData: {
                                    ...prev.cartData,
                                    transfer2: {
                                      ...prev.cartData.transfer2,
                                      [e.target.name]: e.target.value,
                                    },
                                  },
                                };
                              });
                            }}
                            value={cardState.cartData.transfer2.dropOffName2 || ""}
                          />
                          <span></span>
                        </div>
                      </div>{" "}
                      <div className="input-container">
                        <label className={!isFieldsValid.transfer2.dropOffAddress2 ? "label-invalid": ""}>Drop-off Address</label>
                        <div className="input-box">
                          <GooglePlaceAutocompleteInput
                            cardState={cardState}
                            setCardState={setCardState}
                            placeName={"dropOffAddress2"}
                            placeValueName={"dropOffPlaceValue"}
                            placeLinkName={"dropOffPlaceLink"}
                            cardStateField={vegasLimoTransferType}
                          />
                          <span></span>
                        </div>
                      </div>{" "}
                    </div>
                  )}
                  <div className="actions-container">
                    <CardDeclineBtn visible={isServiceDeclined} handleClick={handleDeclineClick} />
                    <Button disabled={isSubmitBtnDisabled} className="btn-round btn-icon d-flex align-items-center save-btn" type="submit">
                      Save
                    </Button>
                  </div>
                </Form>
              </>
            </CardDetails>

            <div className="divider"></div> 

            <CardAction icon={helpIcon} text="Get help" handleClick={() => window.location.href = emailLink} />
          </>
        )}

        {!isPublished && !isMissingInfo &&
          <>
            <CardDetails
              isToggleExpanded={false}
              cardIcon={reservationIcon}
              cardTitle="Departure Reservation Details"
              cardSubtitle="Your transportation is guaranteed."
            >
              <>
                <CardGuaranteed text="Your transfer guaranteed." />
                <div className="actions-container">
                  <CardDeclineBtn visible={isServiceDeclined} handleClick={handleDeclineClick} />
                </div>
              </>
            </CardDetails>
            
            <div className="divider"></div>

            <CardAction icon={helpIcon} text="Get help" handleClick={() => window.location.href = emailLink} />
          </>
        }
      </>
    </StagedCardLayout>
  );
};

export default VegasLimoTransferGroupCardNew;