import { useEffect, useState } from 'react';
import { useApolloClient } from '@apollo/client';
import { Button, Form, Input } from 'reactstrap';
import moment from 'moment';
import { errorMsg, successMsg } from '../../cache/vars';
import { DECLINE_SERVICE, GET_AVAILABLE_FILTERS_FOR_PACKAGE_INFO, SAVE_STAGE_ONE_CARD } from '../../queries/vip-workflow';
import { SERVICE_STATE } from '../../utils/constants';
import StagedCardLayout from '../StagedCard/StagedCardLayout/StagedCardLayout';
import CardDetails from '../StagedCard/CardDetails/CardDetails';
import CardGoogleMap from '../StagedCard/CardGoogleMap/CardGoogleMap';
import CardGuaranteed from '../StagedCard/CardGuaranteed/CardGuaranteed';
import CardAction from '../StagedCard/CardAction/CardAction';
import reservationIcon from '../../assets/img/icons/cards/reservation-icon.svg';
import flagIcon from '../../assets/img/icons/cards/flag-icon.svg';
import mapIcon from '../../assets/img/icons/cards/map-icon.svg';
import utencilsIcon from "../../assets/img/icons/cards/utencils-icon.svg";
import helpIcon from '../../assets/img/icons/cards/help-icon.svg';

import './dinnerGroupCardNew.scss';
import CardDeclineBtn from '../StagedCard/CardDeclineBtn/CardDeclineBtn';
import { generateMailtoLink } from '../../utils/services';

type DinnerGroupCardNewProps = {
  cart: any;
  datetime: any | null;
  time: any;
  isMissingInfo: boolean;
  isPublished: boolean;
  isServiceDeclined: boolean;
  serviceDeclined: string;
  packageDetailsId: number;
  setPackageOrderWithDetails: (data: any) => void;
  decryptedInvoiceId: any;
  setMissingInfoCards: any;
  cardDescription: string;
  cardGroup: string;
};

const DinnerGroupCardNew = ({
  cart,
  datetime,
  time,
  isMissingInfo,
  isPublished,
  isServiceDeclined,
  serviceDeclined,
  packageDetailsId,
  setPackageOrderWithDetails,
  decryptedInvoiceId,
  setMissingInfoCards,
  cardDescription,
  cardGroup,
}: DinnerGroupCardNewProps) => {
  const client = useApolloClient();
  
  const [emailLink, setEmailLink] = useState("");

  const [cardState, setCardState] = useState<{ packageDetailsId: number; cartData: any }>({
    packageDetailsId,
    cartData: { recipientName: "" },
  });

  const [isInvalid, setIsInvalid] = useState(false);
  
  const handleDeclineClick = async () => {
    if (window.confirm("Are you sure you want to decline this service?")) {
      const { data } = await client.mutate({
        mutation: DECLINE_SERVICE,
        variables: { packageDetailsId: packageDetailsId },
      });

      if (data?.declineService) {
        setPackageOrderWithDetails((prev: any) => {
          return {
            ...prev,
            orderDetails: prev.orderDetails.map((item: any) => {
              return item.packageDetailsId === packageDetailsId
                ? {
                    ...item,
                    cartDetails: {
                      ...item.cartDetails,
                      cartId: data?.declineService,
                      isServiceDeclined: SERVICE_STATE.DECLINED,
                    },
                  }
                : item;
            }),
          };
        });
        successMsg("Service Declined");
      }
    }
  };
  
  useEffect(() => {
    generateMailtoLink(client, window.location.pathname, setEmailLink);
  }, [client]);

  const cartJsonData = JSON.parse(cart.cartDetails.cartJsonData);

  return (
    <StagedCardLayout
      cardIcon={utencilsIcon}
      cardTitle={cart?.cartDetails?.cardTitle || "Pre-show Hospitality"}
      datetime={datetime}
      time={time}
      isPublished={isPublished}
      isServiceDeclined={isServiceDeclined}
      serviceDeclined={serviceDeclined}
      isMissingInfo={isMissingInfo}
    >
      <>
        {isPublished &&
          <>
            {cartJsonData.restaurantName &&
              <>
                <CardDetails
                  cardIcon={reservationIcon}
                  cardTitle={cartJsonData.restaurantName}
                  // cardTitle={<span clasName="card-title">Restaurant Name<span className="spacing"></span>{cartJsonData.restaurantName}</span>}
                  cardSubtitle=""
                >
                  <div className="dinner-card-content">
                    {cartJsonData.restaurantPlaceName &&
                      <>
                        <div className="card-content-column">
                          <p>Restaurant Address</p>
                        </div>
                        <div className="card-content-column">
                          <p>{cartJsonData.restaurantPlaceName}</p>
                        </div>
                      </>
                    }
                  </div>
                  <div className="pl-4">
                    {cartJsonData?.restaurantPhoneNumber &&
                      <a href={`tel:${cartJsonData?.restaurantPhoneNumber}`} className="phone-link">
                        {cartJsonData?.restaurantPhoneNumber}
                      </a>
                    }
                  </div>
                  <CardGoogleMap address={cartJsonData?.restaurantPlaceName} className="pl-4" />
                  {/* <div className="dinner-card-content">
                    <div className="card-content-column">
                      <p>Restaurant Phone Number</p>
                    </div>
                    <div className="card-content-column">
                      <p>{cartJsonData.restaurantPhoneNumber}</p>
                    </div>
                  </div> */}
                  
                  <div className="dinner-card-content">
                    {cartJsonData.qty &&
                      <div className="card-content-column">
                        <p>Table for {cartJsonData.qty}</p>
                      </div>
                    }
                    {cartJsonData.alternativeName &&
                    <div className="card-content-column">
                      <p>{cartJsonData.alternativeName}</p>
                    </div>
                    }
                  </div>
                  
                  <div className="dinner-card-content">
                    {cartJsonData.foodBeverageCredit &&
                      <>
                        <div className="card-content-column">
                          <p>Food & Beverage Credit Up To</p>
                        </div>
                        <div className="card-content-column">
                          <p>{cartJsonData.foodBeverageCredit}</p>
                        </div>
                      </>
                    }
                  </div>
                </CardDetails>
                          
                <div className="divider" />
              </>
            }
            
            {cartJsonData.description &&
              <>
                <CardDetails
                cardIcon={flagIcon}
                cardTitle={cartJsonData.description}
              />
              
              <div className="divider" />
              </>
            }
            
            {cartJsonData.notes &&
              <CardDetails
                cardIcon={flagIcon}
                cardTitle="Additional Notes"
                cardDescription={cartJsonData.notes}
              />
            }
          </>
        }

        {!isPublished && isMissingInfo && (
          <>
            <CardDetails
              isEditable={true}
              cardIcon={reservationIcon}
              cardTitle="Pre-show Hospitality"
              cardSubtitle="Please provide your contact info"
            >
              <>
                <div className="divider"></div>
                <Form
                onSubmit={async (e) => {
                  e.preventDefault();
                  const { data } = await client.mutate({
                    mutation: SAVE_STAGE_ONE_CARD,
                    variables: { editCartDetailsInput: cardState },
                  });

                  if (data) {
                    setPackageOrderWithDetails((prev: any) => {
                      return {
                        ...prev,
                        orderDetails: [
                          ...prev.orderDetails.map((cart: any) => {
                            return cart.packageDetailsId === packageDetailsId
                              ? {
                                  ...cart,
                                  cartDetails: {
                                    ...cart.cartDetails,
                                    cardTitle: data?.saveStageOneCard.cardTitle,
                                    cartId: data?.saveStageOneCard.cartId,
                                    cartJsonData: data?.saveStageOneCard.cartJsonData,
                                    isPublished: data?.saveStageOneCard.isPublished,
                                    packageDetailsId: data?.saveStageOneCard.packageDetailsId,
                                  },
                                }
                              : cart;
                          }),
                        ],
                      };
                    });

                    successMsg("Saved");
                  }
                }}
              >
                <div className="inputs-container">
                  <div className="input-container">
                    <label>Alternative Name</label>
                    <div className="input-box">
                      <Input
                        type="text"
                        placeholder="Alternative Name"
                        name="alternativeName"
                        value={cardState.cartData.alternativeName}
                        onChange={(e) => {
                          const value = e.target.value;
                          const hasNumber = /\d/.test(value);
                      
                          if (hasNumber) {
                            setIsInvalid(true);
                          } else {
                            setIsInvalid(false);
                          }
                      
                          if (!hasNumber) {
                            setCardState((prev: any) => {
                              return {
                                ...prev,
                                cartData: { ...prev.cartData, [e.target.name]: e.target.value },
                              };
                            });
                          }
                        }}
                      />
                      {isInvalid && <p className="number-validation" style={{ marginTop: '1rem' }}>Numbers is not allowed</p>}
                    </div>
                  </div>
                </div>
                <div className="actions-container">
                  <CardDeclineBtn visible={isServiceDeclined} handleClick={handleDeclineClick} />
                  <Button className="btn-round btn-icon d-flex align-items-center save-btn" type="submit">
                    Save
                  </Button>
                </div>
              </Form>
              </>
            </CardDetails>
            <div className="divider"></div>
            <CardAction icon={helpIcon} text="Get help" handleClick={() => window.location.href = emailLink} />
          </>
        )}
        
        {!isPublished && !isMissingInfo &&
          <>
            <CardDetails
              isToggleExpanded={false}
              cardIcon={reservationIcon}
              cardTitle="Dinner title"
              cardSubtitle="Your dinner are guaranteed."
            >
              <div className="pl-4 mb-4">
                <CardGuaranteed text="Your dinner are guaranteed." />
                <div className="actions-container">
                  <CardDeclineBtn visible={isServiceDeclined} handleClick={handleDeclineClick} />
                </div>
                <div className="divider"></div> 
                <CardAction icon={helpIcon} text="Get help" handleClick={() => window.location.href = emailLink} />
              </div>
            </CardDetails>
          
            <CardDetails
              cardIcon={flagIcon}
              cardTitle="Additional Notes"
              cardDescription={cartJsonData?.notes || ''}
            />
          </>
        }
      </>
    </StagedCardLayout>
  );
};

export default DinnerGroupCardNew;