import datePickerIcon from '../../assets/img/icons/common/date-picker-mobile-white.svg';
import arrowLeft from '../../assets/img/icons/common/arrow-left.svg';
import "./pageMenuSidebar.scss";
import PackageInfoCartFilters from "./PackageInfoCartFilters";

interface PageMenuSidebarProps {
  isOpen: boolean;
  onClose: () => void;
  packageOrder: any;
  invoiceIdLabel: string;
  eventDates: string;
  address: string;
  activeCardsFilter: string;
  setActiveCardsFilter: (filter: string) => void;
  availableFilters: string[];
  missingInfoCards: any[];
};

const PageMenuSidebar = ({ isOpen, onClose, packageOrder, invoiceIdLabel, eventDates, address, activeCardsFilter, 
  setActiveCardsFilter, availableFilters, missingInfoCards
 }: PageMenuSidebarProps) => {
  
  const formatEventDate = (dateStr: any) => {
    if (!dateStr) return '';

    const [date, time] = dateStr.split(', ');
    const formattedTime = time.toUpperCase().replace(' PM', ' PM').replace(' AM', ' AM');
    return `${date} / ${formattedTime}`;
  };

  return (
    <div className="sidebar-mask">
      <div className="sidebar">
        <div className="header">
          <div className="section back-button" onClick={onClose}>
            <img src={arrowLeft} alt="arrow-left" />
          </div>
        </div>
        
        <div className="scroll-content">
          <div className="content details-container mb-4">
            <img className="mr-2" src={datePickerIcon} alt="picker-icon" />
            <span className="order-details-span">Order Details</span>
          </div>
          
          <div className="thin-border"></div>
        
          <div className="content">
            <div className="mb-3">
              <div className="mb-1">
                <span className="venue-name-span">{packageOrder?.eventName}</span>
              </div>
              <div className="mb-1">
                <span className="venue-address-span">{address}</span>
              </div>
              <div className="mb-1">
                <span className="venue-date-span">
                  {packageOrder?.eventDate?.includes('3:30 am')
                    ? packageOrder.eventDate.replace('3:30 am', 'Time TBD')
                    : formatEventDate(packageOrder?.eventDate)}
                </span>
              </div>
            </div>

            <div className="mb-3">
              <div>
                <span className="package-section-span">
                  {packageOrder?.section} {packageOrder?.row}
                </span>
              </div>
              
              <div>
                <span className="package-start-date-span">
                  {eventDates}
                </span>
              </div>
            </div>

            <div className="mb-2">
              <div>
                <span className="recipient-span">{packageOrder?.recipient}</span>
              </div>
              <div>
                <span className="recipient-email-span">{packageOrder?.recipientEmail}</span>
              </div>
              <div>
                <span className="order-details-po-span,">
                  Order ID:&nbsp;
                  {packageOrder?.externalPo || '-'}
                </span>
              </div>
              <div className="mb-3">
                <span className="invoice-id-span">{invoiceIdLabel}</span>
              </div>
            </div>
            
            <div className="thin-border mb-3"></div>

            <div>
              <p>
                <span className="attention-span">There are items that need your attention.</span>
              </p>
            </div>
            <div className="scrollable-cards-container" onClick={onClose}>
              <PackageInfoCartFilters
                activeCardsFilter={activeCardsFilter}
                setActiveCardsFilter={setActiveCardsFilter}
                availableFilters={missingInfoCards}
                missingInfoCards={missingInfoCards}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PageMenuSidebar;
