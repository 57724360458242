import { useEffect, useState } from 'react';
import moment from 'moment';
import cardBackgroundImg from '../../assets/img/images/card-background.png';
import getTicketsImgMobile from '../../assets/img/images/modal-popup-mobile.png';
import './informationalStickyBlock.scss';

const InformationalStickyBlock = ({
  packageOrder,
  setIsModalOpened,
}: any) => {
  const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 575);

  const eventDates =
    moment(packageOrder?.startDate, 'MMMM Do YYYY').toLocaleString() ===
    moment(packageOrder?.endDate, 'MMMM Do YYYY').toLocaleString()
      ? packageOrder?.startDate
      : packageOrder?.startDate + ' - ' + packageOrder?.endDate;

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 575);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  
  if (!isMobileView) return null;

  return (
    <div className="informational-container">
      <div className="d-flex align-items-center justify-content-between p-2">
        <div className="d-flex align-items-center">
          <img
            className="sticky-card-img"
            src={cardBackgroundImg}
            alt="card-bg-img"
          />
          <div className="px-2">
            <p className="section-info-p-mobile text-white m-0">
              {packageOrder?.eventName}
            </p>
            <p className="event-dates-info-mobile text-white m-0">{eventDates}</p>
          </div>
        </div>
        <div className="get-tickets-info-container mr-2">
          <img
            className="get-tickets-img"
            src={getTicketsImgMobile}
            alt="get-ticket-img"
            onClick={() => {
              setIsModalOpened(true);
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default InformationalStickyBlock;
