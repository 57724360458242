import moment from "moment";
import React from "react";
import { UncontrolledPopover, PopoverBody, Spinner } from "reactstrap";

export type FlightInfoRes = IFlightInfo[];

export interface IFlightInfo {
  departureAirport?: string;
  departureScheduleTime?: string;
  departureActualTime?: string;
  departureDate?: string;
  departureTerminal?: string;
  departureGate?: string;
  arrivalAirport?: string;
  arrivalDate?: string;
  arrivalScheduleTime?: string;
  arrivalActualTime?: string;
  arrivalTerminal?: string;
  arrivalGate?: string;
  status?: string;
}

const FlightInfoPopover = ({
  parsedCardDetails,
  config,
}: {
  parsedCardDetails: any;
  config: any;
}) => {
  const [isPopoverOpen, setIsPopoverOpen] = React.useState("");
  const [isPopoverLoading, setIsPopoverLoading] = React.useState(false);
  const [flightInfo, setFlightInfo] = React.useState<FlightInfoRes>([]);
  const [flightInfoResSuccess, setFlightInfoResSuccess] = React.useState(true);

  const handleTogglePopover = (popoverId: string) => {
    if (isPopoverOpen === popoverId) {
      setIsPopoverOpen("");
    } else {
      setIsPopoverOpen(popoverId);
    }
  };

  React.useEffect(() => {
    setIsPopoverOpen("");
  }, [parsedCardDetails?.transferId]);
  return (
    <>
      <button
        style={{ border: "0", background: "none", padding: "0" }}
        onClick={async (e) => {
          e.preventDefault();
          e.stopPropagation();

          setIsPopoverLoading(true);

          const flightInfoDataRequest = await fetch(
            `https://api.flightapi.io/flighttrack/645b9672aa7ee2d83b653cf0?airlinecode=${
              parsedCardDetails.flightNumber?.match(/[aA-zZ]+/) ||
              parsedCardDetails.departureFlightNumber?.match(/[aA-zZ]+/)
            }&airplanecode=${
              parsedCardDetails.flightNumber?.match(/[0-9]+/) ||
              parsedCardDetails.departureFlightNumber?.match(/[0-9]+/)
            }&year=${moment(parsedCardDetails.departureFlightDate).format("YYYY")}&month=${moment(
              parsedCardDetails.departureFlightDate,
            ).format("MM")}&day=${moment(parsedCardDetails.departureFlightDate).format("DD")}`,
          );

          const flightInfoData = await flightInfoDataRequest.json();

          if (!flightInfoData.length && !flightInfoData.success) {
            setFlightInfoResSuccess(false);
            setIsPopoverLoading(false);
          }

          setFlightInfo(flightInfoData);
          setIsPopoverLoading(false);
        }}
        className="btn-cell"
        id={`id-${config.name}`}
      >
        <i className="fa fa-lg fa-regular fa-circle-info ml-2" />
      </button>
      <UncontrolledPopover
        target={`id-${config.name}`}
        toggle={() => {
          setFlightInfo([]);
          handleTogglePopover(`id-${config.name}`);
        }}
        placement="right"
        isOpen={isPopoverOpen === `id-${config.name}`}
      >
        <PopoverBody onClick={(e) => e.stopPropagation()}>
          {isPopoverLoading ? (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Spinner animation="border" variant="info" size="lg" />
            </div>
          ) : flightInfoResSuccess ? (
            <div>
              <div className="text-right">
                <i
                  className="fas fa-window-close"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleTogglePopover(`id-${config.name}`);
                  }}
                  style={{ cursor: "pointer" }}
                />
              </div>
              {flightInfo.length &&
                Object.entries(
                  flightInfo[Number.parseFloat(parsedCardDetails.transferId) === 1 ? 1 : 0],
                ).map(([key, value], id: number) => {
                  return (
                    <div key={id}>
                      <strong className="text-capitalize">
                        {key.replace(/([A-Z])/g, " $1")}:&nbsp;&nbsp;
                      </strong>
                      <span>{value}</span>
                    </div>
                  );
                })}
            </div>
          ) : (
            <span>Info Not Found</span>
          )}
        </PopoverBody>
      </UncontrolledPopover>
    </>
  );
};

export default FlightInfoPopover;
