import moment from "moment";
import { formatPrice } from "./services";

import airportIcon from "../assets/img/icons/cards/airport-icon.svg";
import transportingIcon from "../assets/img/icons/cards/transporting-icon.svg";
import utencilsIcon from "../assets/img/icons/cards/utencils-icon.svg";
import ticketIcon from "../assets/img/icons/cards/ticket-icon.svg";
import giftIcon from "../assets/img/icons/cards/gift-icon.svg"
import buildingIcon from "../assets/img/icons/cards/building-icon.svg";
import receiptIcon from "../assets/img/icons/cards/receipt-icon.svg";
import handShakeIcon from "../assets/img/icons/cards/handshake-icon.svg";
import parkingIcon from "../assets/img/icons/cards/parking-icon.svg";
import dollarIcon from "../assets/img/icons/cards/dollar-icon.svg";
import partyPooperIcon from "../assets/img/icons/cards/party-pooper-icon.svg";

import copyIcon from "../assets/img/icons/cards/copy-icon.svg";
import carIcon from "../assets/img/icons/cards/car-config-icon.svg";
import mapIcon from "../assets/img/icons/cards/map-icon.svg";
import starIcon from "../assets/img/icons/cards/star-icon.svg";
import pencilIcon from "../assets/img/icons/cards/pencil-icon.svg";
import flameIcon from "../assets/img/icons/cards/flame-icon.svg";
import ticketsIcon from "../assets/img/icons/cards/ticket-config-icon.svg";
import giftsIcon from "../assets/img/icons/cards/gift-config-icon.svg";
import appartmentIcon from "../assets/img/icons/cards/appartment-config-icon.svg";
import giftCardIcon from "../assets/img/icons/cards/gift-card-icon.svg";

export const DOTS = "DOTS";

export const CRYPTO_JS_SECRET = "sdSH67ksdGHDbdfshwe23gbsY7820vHGds";

export const tableFieldTypes = {
  price: "price",
  bool: "bool",
  percent: "percent",
  string: "string",
  number: "number",
};

const DELIVERED_OPTIONS = {
  IP: "In Package",
  OD: "Ordered/Delivered",
  ND: "Needs Delivery",
};

export const TABLE_INPUT_TYPES = {
  text: "text",
  number: "number",
  datetime: "datetime-local",
  phoneNumber: "tel",
  textarea: "textarea",
  select: "select",
  time: "time",
  checkbox: "checkbox",
  file: "file",
};

// const STATUS_OPTIONS = {
//   1: "Pending Authorization",
//   2: "Authorized",
//   4: "Rejected",
//   5: "Ready To Ship",
//   6: "Rejected",
//   7: "Void",
// };

// const VALIDATED_OPTIONS = {
//   0: "No",
//   1: "Yes",
// };

// const FULFILL_OPTIONS = {
//   0: "No",
//   1: "Yes",
// };

const PARKING_OPTIONS = {
  Mobile: "Your parking pass will be transfered to you",
  "Not Mobile": "Your parking pass is available here",
  "License plate required":
    "Your vehicle make and license plate # is connected to your parking pass.",
  ParkWhiz:
    "Parking will be emailed to you from ParkWhiz. Check your spam folder if you do not see it by 24 hours before the event. Please note: Some parking locations will ask for vehicle information, including license plate number, to be added to the parking pass. You will be prompted to do so when viewing your parking pass. If you are not prompted to add any information you are good to go!",
};

const TAILGATE_OPTIONS = (tailgateOption: string, recipientName: string) => {
  const tailgateDescription = {
    "Guest List": `Your Tailgate Flyer including all information regarding your tailgate event is attached. ${recipientName} name was placed on the Guest List for entry for the quantity purchased.`,
    "E-tickets":
      "Your tailgate passes are attached to this email for you to print out in advance of the event and present for entry to the party.",
    Mobile:
      "Your tailgate passes will be transferred to your email and need to be presented on a mobile device for entry to the event.",
    "External Send":
      " Your tailgate passes will be emailed to you separately for you to print and present at the party for entry.",
  };

  return tailgateDescription[tailgateOption as keyof typeof tailgateDescription];
};

export const CART_DETAILS_HEADER_CONFIG = {
  csr: {
    colTitle: "CSR",
    type: tableFieldTypes.string,
  },
  budget: {
    colTitle: "Budget",
    type: (item: any) => {
      return item.type === "Management Fee" ? tableFieldTypes.percent : tableFieldTypes.price;
    },
  },
  cost: {
    colTitle: "Cost",
    type: tableFieldTypes.price,
  },
  purchaseOrderId: {
    colTitle: "PO",
    type: tableFieldTypes.number,
    render: (value: string) => {
      if (!value) {
        return "Unassigned";
      }

      return value;
    },
  },
  delivered: {
    colTitle: "Delivery Status",
    type: tableFieldTypes.string,
    render: (value: string) => {
      if (!value) {
        return "Unassigned";
      }

      return DELIVERED_OPTIONS[value as keyof typeof DELIVERED_OPTIONS];
    },
  },
  description: {
    colTitle: "Description",
    type: tableFieldTypes.string,
  },
} as const;

const TRANSPORTATION_SERVICES_CONFIG = [
  {
    defaultValue: "-",
    inputType: TABLE_INPUT_TYPES.text,
    label: "Vendor Name",
    name: "vendorName",
    placeholder: "Enter Vendor Name",
    bold: true,
    showMoreButton: true,
    icon: carIcon,
    skipIfInput: true,
    checkPending: true,
  },
  {
    cartInfo: true,
    checkPending: true,
    vendorPhone: {
      defaultValue: "-",
      inputType: TABLE_INPUT_TYPES.phoneNumber,
      style: { marginTop: "6px", marginLeft: "0.25rem" },
      label: "Vendor Phone",
      name: "vendorPhone",
      placeholder: "Enter Vendor Phone",
      skipIfInput: true,
      checkPending: true,
    },
    confirmationNumber: {
      defaultValue: "-",
      inputType: TABLE_INPUT_TYPES.text,
      style: { marginTop: "6px", marginLeft: "0.25rem" },
      label: "Confirmation Number",
      name: "confirmationNumber",
      placeholder: "Enter Confirmation Number",
      skipIfInput: true,
      checkPending: true,
    },
  },
  {
    defaultValue: "-",
    inputType: TABLE_INPUT_TYPES.datetime,
    label: "Pick Up At",
    name: "date",
    placeholder: "Enter Pick Up Time",
    bold: true,
    style: { marginTop: "20px" },
    render: (date: Date) => {
      if (!new Date(date).getTime()) {
        return "-";
      }
      return moment(date).format("hh:mm a");
    },
    skipIfInput: true,
    icon: mapIcon,
    checkPending: true,
  },
  {
    defaultValue: "-",
    inputType: TABLE_INPUT_TYPES.text,
    style: { marginTop: "6px" },
    label: "Pick Up Address",
    name: "pickUpAddress",
    placeholder: "Enter Pick Up Address",
    checkIfNotNull: true,
    linkLabel: "pickUpPlaceName",
    linkValue: "pickUpPlaceLink",
    isLink: true,
    isAddress: true,
  },
  {
    defaultValue: "-",
    inputType: TABLE_INPUT_TYPES.datetime,
    style: { marginTop: "20px" },
    label: "Pre-show Hospitality At",
    name: "preShowHospitality",
    placeholder: "Enter Pre-show hospitality",
    render: (date: Date) => {
      if (!new Date(date).getTime()) {
        return "-";
      }
      return moment(date).format("h:mm a");
    },
    skipIfInput: true,
    checkPending: true,
    bold: true,
    icon: starIcon,
  },
  {
    inputType: TABLE_INPUT_TYPES.text,
    style: { marginTop: "6px" },
    label: "Restaurant Name",
    name: "restaurantName",
    defaultValue: "-",
  },
  {
    inputType: TABLE_INPUT_TYPES.text,
    style: { marginTop: "6px" },
    label: "Restaurant Address",
    name: "restaurantAddress",
    defaultValue: "-",
    checkPending: true,
    linkLabel: "restaurantPlaceName",
    linkValue: "restaurantPlaceLink",
    isAddress: true,
  },
  {
    defaultValue: "-",
    inputType: TABLE_INPUT_TYPES.datetime,
    style: { marginTop: "20px" },
    label: "Event At",
    name: "startTime",
    placeholder: "Enter Start time of Event",
    render: (date: Date) => {
      if (!new Date(date).getTime()) {
        return "-";
      }
      return moment(date).format("h:mm a");
    },
    skipIfInput: true,
    checkPending: true,
    bold: true,
    icon: flameIcon,
  },
  {
    defaultValue: "-",
    inputType: TABLE_INPUT_TYPES.text,
    style: { marginTop: "6px" },
    label: "Event Venue Name",
    name: "venue",
    placeholder: "Enter Event Venue",
    skipIfInput: true,
    checkPending: true,
  },
  {
    inputType: TABLE_INPUT_TYPES.text,
    style: { marginTop: "6px" },
    label: "Event Venue Address",
    checkPending: true,
    name: "venueAddress",
    placeholder: "Enter Venue Address",
    defaultValue: "-",
    isEventDataWasModified: "isEventVenueAddressWasModified",
    keyForReveringValue: "streetAddress1",
    linkLabel: "venueAddress",
    linkValue: "placeLink",
    isAddress: true,
  },
  {
    defaultValue: "-",
    inputType: TABLE_INPUT_TYPES.datetime,
    label: "Drop Off At",
    name: "dropOff",
    placeholder: "Enter Drop Off Time",
    bold: true,
    style: { marginTop: "20px" },
    render: (date: Date) => {
      if (!new Date(date).getTime()) {
        return "-";
      }
      return moment(date).format("MMMM Do YYYY, h:mm a");
    },
    skipIfInput: true,
    icon: mapIcon,
    checkPending: true,
  },
  {
    defaultValue: "-",
    inputType: TABLE_INPUT_TYPES.text,
    style: { marginTop: "6px" },
    label: "Drop Off Address",
    name: "dropOffAddress",
    placeholder: "Enter Drop Off Address",
    checkIfNotNull: true,
    linkLabel: "dropOffPlaceName",
    linkValue: "dropOffPlaceLink",
    isLink: true,
    isAddress: true,
  },
  {
    defaultValue: "-",
    inputType: TABLE_INPUT_TYPES.textarea,
    label: "Additional Notes",
    name: "notes",
    placeholder: "Enter Notes",
    style: { marginTop: "20px" },
    skipIfInput: true,
    icon: pencilIcon,
    hideIfEmpty: true,
  },
];

export const CARD_FORM_SCHEMA = {
  Dinner: [
    {
      inputType: TABLE_INPUT_TYPES.text,
      label: "Restaurant Name",
      name: "restaurantName",
      placeholder: "Enter Restaurant Name",
      bold: true,
      showMoreButton: true,
      defaultValue: "-",
      icon: starIcon,
      checkPending: true,
    },
    {
      cartInfo: true,
      checkPending: true,
      streetAddress: {
        inputType: TABLE_INPUT_TYPES.text,
        style: { marginTop: "6px", marginLeft: "0.25rem" },
        label: "Restaurant Address",
        name: "restaurantAddress",
        placeholder: "Enter Restaurant Address",
        defaultValue: "-",
        checkPending: true,
        linkLabel: "restaurantPlaceName",
        linkValue: "restaurantPlaceLink",
        isAddress: true,
      },
      phoneNumber: {
        inputType: TABLE_INPUT_TYPES.phoneNumber,
        style: { marginTop: "6px", marginLeft: "0.25rem" },
        label: "Restaurant Phone Number",
        name: "restaurantPhoneNumber",
        placeholder: "Enter Restaurant Phone Number",
        defaultValue: "-",
        checkPending: true,
      },
    },
    {
      inputType: TABLE_INPUT_TYPES.number,
      min: 1,
      style: { marginTop: "6px" },
      label: (qty: string | null, showDefault: boolean) => {
        if (!showDefault) {
          return `Table For ${qty} For`;
        }

        return "Table For";
      },
      name: "qty",
      placeholder: "Enter Quantity",
      showDataFrom: "recipientName",
      alternativeDataName: "alternativeName",
      render: (qty: string, recipientName: number, alternativeData: string | null) => {
        return alternativeData ? alternativeData : recipientName;
      },
      defaultValue: "-",
      checkPending: true,
    },
    {
      inputType: TABLE_INPUT_TYPES.text,
      style: { marginTop: "6px" },
      label: "Name or Alternative Name",
      checkPending: true,
      name: "recipientName",
      placeholder: "Enter Name or Alternative Name",
      skipInCart: true,
      defaultValue: "-",
    },
    {
      inputType: TABLE_INPUT_TYPES.datetime,
      style: { marginTop: "6px" },
      label: "Date",
      checkPending: true,
      name: "date",
      placeholder: "Enter Date",
      render: (date: Date) => {
        if (!new Date(date).getTime()) {
          return "-";
        }
        return moment(date).format("MM/DD/YYYY");
      },
      skipInCart: true,
      defaultValue: "-",
    },
    {
      inputType: TABLE_INPUT_TYPES.text,
      style: { marginTop: "6px" },
      label: "Food & Beverage Credit",
      labelInCart: "Food & Beverage Credit Up To",
      checkPending: true,
      name: "foodBeverageCredit",
      placeholder: "Enter Food & Beverage Credit",
      render: (foodBeverageCredit: string) => {
        return formatPrice(foodBeverageCredit);
      },
      defaultValue: "-",
    },
    // {
    //   label: "Learn More",
    //   style: { marginTop: "6px" },
    //   skipIfInput: true,
    //   learnMoreButton: true,
    //   icon: "fa fa-lg fa-regular fa-circle-info",
    // },
    // {
    //   learnMore: true,
    //   style: { marginTop: "6px" },
    //   name: "date",
    //   showDataFrom: "foodBeverageCredit",
    //   render: (date: string, foodBeverageCredit: string) => {
    //     return `Enjoy your hospitality starting at ${
    //       new Date(date).getTime() ? moment(date).format("h:mm a") : "-"
    //     } with an open menu up to ${formatPrice(
    //       foodBeverageCredit,
    //     )} including tax and gratuity. Please be timely for your reservation. The restaurant will only hold your table for up to 15 minutes past your reservation time. If you are running late, please contact the restaurant directly.`;
    //   },
    //   skipIfInput: true,
    //   defaultValue: "-",
    // },
    {
      defaultValue: "-",
      style: { marginTop: "20px" },
      label: "",
      alignToLeft: true,
      name: "description",
      icon: pencilIcon,
      hideIfEmpty: true,
    },
    {
      defaultValue: "-",
      inputType: TABLE_INPUT_TYPES.textarea,
      style: { marginTop: "20px" },
      label: "Additional Notes",
      name: "notes",
      placeholder: "Enter Additional Notes",
      icon: pencilIcon,
      hideIfEmpty: true,
    },
  ],
  Ticket: [
    {
      inputType: TABLE_INPUT_TYPES.datetime,
      style: { marginBottom: "6px" },
      label: "Date",
      checkPending: true,
      name: "date",
      placeholder: "Enter Date",
      render: (date: Date) => {
        if (!new Date(date).getTime()) {
          return "-";
        }
        return moment(date).format("MMMM Do YYYY, h:mm a");
      },
      defaultValue: "-",
      skipInCart: true,
      isEventDataWasModified: "isEventDateWasModified",
      keyForReveringValue: "eventDate",
    },
    {
      inputType: TABLE_INPUT_TYPES.text,
      label: "Event",
      checkPending: true,
      name: "performerName",
      placeholder: "Enter Performer Name",
      bold: true,
      style: { marginBottom: "20px" },
      disabled: true,
      defaultValue: "-",
      icon: flameIcon,
    },
    {
      inputType: TABLE_INPUT_TYPES.text,
      label: "Venue Name",
      labelInCart: "Venue",
      checkPending: true,
      name: "venue",
      placeholder: "Enter Venue Name",
      bold: true,
      style: { marginTop: "20px" },
      showMoreButton: true,
      defaultValue: "-",
      isEventDataWasModified: "isEventVenueWasModified",
      keyForReveringValue: "venue",
    },
    {
      cartInfo: true,
      checkPending: true,
      defaultDisclaimer: {
        inputType: TABLE_INPUT_TYPES.text,
        style: { marginTop: "6px", marginLeft: "0.25rem" },
        label: "Default Disclaimer",
        checkPending: true,
        name: "defaultDisclaimer",
        placeholder: "Enter Default Disclaimer",
        defaultValue: "-",
      },
      venueAddress: {
        inputType: TABLE_INPUT_TYPES.text,
        style: { marginTop: "6px", marginLeft: "0.25rem" },
        label: "Venue Address",
        checkPending: true,
        name: "venueAddress",
        placeholder: "Enter Venue Address",
        defaultValue: "-",
        isEventDataWasModified: "isEventVenueAddressWasModified",
        keyForReveringValue: "streetAddress1",
        linkLabel: "placeName",
        linkValue: "placeLink",
        isAddress: true,
      },
      venueCity: {
        inputType: TABLE_INPUT_TYPES.text,
        style: { marginTop: "6px", marginLeft: "0.25rem" },
        label: "Venue City, State, ZIP",
        checkPending: true,
        name: "venueCity",
        placeholder: "Enter Venue City, State, ZIP",
        defaultValue: "-",
      },
      venuePhoneNumber: {
        inputType: TABLE_INPUT_TYPES.phoneNumber,
        style: { marginTop: "6px", marginLeft: "0.25rem" },
        label: "Venue Phone Number",
        checkPending: true,
        name: "venuePhoneNumber",
        placeholder: "Enter Venue Phone Number",
        defaultValue: "-",
      },
      website: {
        inputType: TABLE_INPUT_TYPES.text,
        style: { marginTop: "6px", marginLeft: "0.25rem !important" },
        label: "Venue Website",
        checkPending: true,
        name: "website",
        placeholder: "Enter Venue Website",
        isLink: true,
        defaultValue: "-",
      },
    },
    {
      inputType: TABLE_INPUT_TYPES.datetime,
      style: { marginTop: "6px" },
      label: "Expected Arrival",
      checkPending: true,
      name: "expectedArrival",
      placeholder: "Enter Expected Arrival",
      defaultValue: "-",
      render: (date: Date) => {
        if (!new Date(date).getTime()) {
          return "-";
        }
        return moment(date).format("MMMM Do YYYY");
      },
      hideIfFieldWithNameHasValue: "ticketsSent",
    },
    {
      inputType: TABLE_INPUT_TYPES.checkbox,
      style: { marginTop: "6px" },
      label: "Tickets",
      name: "ticketsSent",
      showDataFrom: "ticketsSentDate",
      render: (isTicketsSent: boolean | string, ticketsSentDate: string) => {
        if (ticketsSentDate && ticketsSentDate !== "-") {
          return `Your tickets were sent on ${moment(ticketsSentDate).format("MMMM Do YYYY")}`;
        }

        if (isTicketsSent) {
          return "Your tickets have been sent";
        } else {
          return "-";
        }
      },
      icon: ticketsIcon,
      bold: true,
    },
    {
      defaultValue: "-",
      inputType: TABLE_INPUT_TYPES.textarea,
      style: { marginTop: "20px" },
      label: "Additional Notes",
      name: "notes",
      placeholder: "Enter Additional Notes",
      icon: pencilIcon,
      hideIfEmpty: true,
      isTextContainsLink: true,
      render: (note: string) => {
        return note;
      },
      alignToLeft: true,
    },
  ],
  "Gift Bundle": [
    {
      defaultValue: "-",
      name: "description",
      placeholder: "",
      skipIfInput: true,
      icon: giftsIcon,
      alignToLeft: true,
      hideIfEmpty: true,
    },
    {
      defaultValue: "-",
      inputType: TABLE_INPUT_TYPES.text,
      style: { marginTop: "6px" },
      label: "Tracking Number",
      name: "trackingNumber",
      placeholder: "Enter Tracking Number",
      hideFieldIfValueEqualsTo: "OVERRIDE",
      hideIfEmpty: true,
    },
    {
      defaultValue: "-",
      inputType: TABLE_INPUT_TYPES.textarea,
      style: { marginTop: "20px" },
      label: "Additional Notes",
      name: "notes",
      placeholder: "Enter Additional Notes",
      icon: pencilIcon,
      hideIfEmpty: true,
    },
  ],
  Hotel: [
    {
      defaultValue: "-",
      inputType: TABLE_INPUT_TYPES.text,
      label: "Hotel Name",
      checkPending: true,
      name: "hotelName",
      placeholder: "Enter Hotel Name",
      bold: true,
      showMoreButton: true,
      icon: appartmentIcon,
    },
    {
      cartInfo: true,
      checkPending: true,
      hotelAddress: {
        defaultValue: "-",
        inputType: TABLE_INPUT_TYPES.text,
        style: { marginTop: "6px", marginLeft: "0.25rem" },
        label: "Hotel Address",
        checkPending: true,
        name: "hotelAddress",
        placeholder: "Enter Hotel Address",
        linkLabel: "placeName",
        linkValue: "placeLink",
        isAddress: true,
      },
      hotelCity: {
        defaultValue: "-",
        inputType: TABLE_INPUT_TYPES.text,
        style: { marginTop: "6px", marginLeft: "0.25rem" },
        label: "Hotel City, State, ZIP",
        checkPending: true,
        name: "hotelCity",
        placeholder: "Enter Hotel City, State, ZIP",
      },
      hotelPhone: {
        defaultValue: "-",
        inputType: TABLE_INPUT_TYPES.phoneNumber,
        style: { marginTop: "6px", marginLeft: "0.25rem" },
        label: "Hotel Phone",
        checkPending: true,
        name: "hotelPhone",
        placeholder: "Enter Hotel Phone",
      },
    },
    {
      defaultValue: "-",
      inputType: TABLE_INPUT_TYPES.datetime,
      label: "Check In Starting At",
      checkPending: true,
      name: "date",
      placeholder: "Enter Check In Time",
      bold: true,
      style: { marginTop: "20px" },
      render: (date: Date) => {
        if (!new Date(date).getTime()) {
          return "-";
        }
        return moment(date).format("hh:mm a");
      },
      skipIfInput: true,
    },
    {
      defaultValue: "-",
      inputType: TABLE_INPUT_TYPES.number,
      min: 1,
      style: { marginTop: "6px" },
      label: "Number of Nights",
      labelInCart: "Number of Nights, Rooms",
      checkPending: true,
      name: "numberOfNights",
      showDataFrom: "roomsNumber",
      placeholder: "Enter Number of Nights",
      render: (numberOfNights: number, numberOfRooms: number) => {
        return `${numberOfNights} ${numberOfNights > 1 ? "Nights" : "Night"}, ${numberOfRooms} ${
          numberOfRooms > 1 ? "Rooms" : "Room"
        }`;
      },
    },
    {
      defaultValue: "-",
      inputType: TABLE_INPUT_TYPES.number,
      min: 1,
      style: { marginTop: "6px" },
      label: "Number of Rooms",
      checkPending: true,
      name: "roomsNumber",
      placeholder: "Enter Number of Rooms",
      skipInCart: true,
    },
    {
      defaultValue: "-",
      inputType: TABLE_INPUT_TYPES.text,
      style: { marginTop: "6px" },
      label: "Name Of Reservation",
      checkPending: true,
      name: "reservationName",
      placeholder: "Enter Name Of Reservation",
    },
    {
      defaultValue: "-",
      inputType: TABLE_INPUT_TYPES.number,
      min: 1,
      style: { marginTop: "6px" },
      label: "Number Of Occupants",
      checkPending: true,
      name: "occupantsNumber",
      placeholder: "Enter Number Of Occupants",
    },
    {
      defaultValue: "-",
      inputType: TABLE_INPUT_TYPES.text,
      style: { marginTop: "6px" },
      label: "Confirmation",
      checkPending: true,
      name: "confirmation",
      placeholder: "Enter Confirmation",
    },
    {
      defaultValue: "-",
      inputType: TABLE_INPUT_TYPES.datetime,
      style: { marginTop: "6px" },
      label: "Date",
      checkPending: true,
      name: "date",
      placeholder: "Enter Date",
      render: (date: Date) => {
        if (!new Date(date).getTime()) {
          return "-";
        }
        return moment(date).format("MM/DD/YYYY");
      },
      skipInCart: true,
    },
    {
      defaultValue: "-",
      inputType: TABLE_INPUT_TYPES.textarea,
      style: { marginTop: "20px" },
      label: "Additional Notes",
      name: "notes",
      placeholder: "Enter Additional Notes",
      icon: pencilIcon,
      hideIfEmpty: true,
    },
  ],
  Limo: TRANSPORTATION_SERVICES_CONFIG,
  Transfer: TRANSPORTATION_SERVICES_CONFIG,
  'Vegas Limo Transfer': {
    transfer1: [
      {
        defaultValue: '-',
        inputType: TABLE_INPUT_TYPES.text,
        label: 'Vendor Name',
        name: 'vendorName1',
        placeholder: 'Enter Vendor Name',
        bold: true,
        style: { marginTop: '20px' },
        showMoreButton: true,
      },
      {
        cartInfo: true,
        vendorPhone: {
          defaultValue: '-',
          inputType: TABLE_INPUT_TYPES.phoneNumber,
          style: { marginTop: '6px', marginLeft: '0.25rem' },
          label: 'Vendor Phone',
          name: 'vendorPhone1',
          placeholder: 'Enter Vendor Phone',
        },
      },
      {
        defaultValue: '-',
        inputType: TABLE_INPUT_TYPES.datetime,
        label: 'Pick Up At',
        name: 'pickUp1',
        placeholder: 'Enter Pick Up Time',
        bold: true,
        style: { marginTop: '20px' },
        render: (date: Date) => {
          if (!new Date(date).getTime()) {
            return '-';
          }
          return moment(date).format('MMMM Do YYYY, h:mm a');
        },
      },
      {
        defaultValue: '-',
        inputType: TABLE_INPUT_TYPES.text,
        style: { marginTop: '6px' },
        label: 'Pick Up Name',
        name: 'pickUpName1',
        placeholder: 'Enter Pick Up Name',
        isShowAsterisk: true,
        linkLabel: 'pickUpName',
      },
      {
        defaultValue: '-',
        inputType: TABLE_INPUT_TYPES.text,
        style: { marginTop: '6px' },
        label: 'Pick Up Address',
        name: 'pickUpAddress1',
        placeholder: 'Enter Pick Up Address',
        isGooglePlaceInput: true,
        render: (googleAddress: string, defaultAddress: any) => {
          return !googleAddress || googleAddress === '-' ? defaultAddress : googleAddress;
        },
        isShowAsterisk: true,
        isDisabled: false,
        isAddress: true,
      },
      {
        defaultValue: '-',
        style: { marginTop: '20px' },
        alignToLeft: true,
        skipIfInput: true,
      },
      {
        defaultValue: '-',
        inputType: TABLE_INPUT_TYPES.text,
        style: { marginTop: '6px' },
        label: 'Contact Name',
        name: 'contactName1',
        placeholder: 'Enter Contact Name',
        isShowAsterisk: true,
      },
      {
        defaultValue: '-',
        inputType: TABLE_INPUT_TYPES.text,
        style: { marginTop: '6px' },
        label: 'Contact Number',
        name: 'contactNumber1',
        placeholder: 'Enter Contact Number',
        isShowAsterisk: true,
      },
      {
        inputType: TABLE_INPUT_TYPES.text,
        label: 'Transporting to',
        name: '',
        placeholder: '',
        bold: true,
        style: { marginTop: '20px' },
        skipIfInput: true,
      },
      {
        defaultValue: '-',
        inputType: TABLE_INPUT_TYPES.text,
        style: { marginTop: '6px' },
        label: 'Drop Off Name',
        name: 'dropOffName1',
        placeholder: 'Enter Drop Off Name',
        isShowAsterisk: true,
        linkLabel: 'dropOffName',
      },
      {
        defaultValue: '-',
        inputType: TABLE_INPUT_TYPES.text,
        style: { marginTop: '6px' },
        label: 'Drop Off Address',
        name: 'dropOffAddress1',
        placeholder: 'Enter Drop Off Address',
        isGooglePlaceInput: true,
        render: (googleAddress: string, defaultAddress: any) => {
          return !googleAddress || googleAddress === '-' ? defaultAddress : googleAddress;
        },
        isShowAsterisk: true,
        isDisabled: false,
        isAddress: true,
      },
      {
        defaultValue: '-',
        style: { marginTop: '6px' },
        label: 'Celebration',
        name: 'celebration1',
        skipIfInput: true,
        isShowAsterisk: true,
      },
      {
        defaultValue: '-',
        inputType: TABLE_INPUT_TYPES.textarea,
        style: { marginTop: '20px' },
        label: 'Additional Notes',
        name: 'notes1',
        placeholder: 'Enter Additional Notes',
      },
      {
        defaultValue: '-',
        style: { marginTop: '20px' },
        render: () => {
          return '* Fields filled in by the recipient';
        },
        alignToLeft: true,
        skipIfInput: true,
      },
    ],
    transfer2: [
      {
        defaultValue: '-',
        inputType: TABLE_INPUT_TYPES.text,
        label: 'Vendor Name',
        name: 'vendorName2',
        placeholder: 'Enter Vendor Name',
        bold: true,
        style: { marginTop: '20px' },
        showMoreButton: true,
      },
      {
        cartInfo: true,
        vendorPhone: {
          defaultValue: '-',
          inputType: TABLE_INPUT_TYPES.phoneNumber,
          style: { marginTop: '6px', marginLeft: '0.25rem' },
          label: 'Vendor Phone',
          name: 'vendorPhone2',
          placeholder: 'Enter Vendor Phone',
        },
      },
      {
        defaultValue: '-',
        inputType: TABLE_INPUT_TYPES.datetime,
        label: 'Pick Up At',
        name: 'pickUp2',
        placeholder: 'Enter Pick Up Time',
        bold: true,
        style: { marginTop: '20px' },
        render: (date: Date) => {
          if (!new Date(date).getTime()) {
            return '-';
          }
          return moment(date).format('MMMM Do YYYY, h:mm a');
        },
      },
      {
        defaultValue: '-',
        inputType: TABLE_INPUT_TYPES.text,
        style: { marginTop: '6px' },
        label: 'Pick Up Name',
        name: 'pickUpName2',
        placeholder: 'Enter Pick Up Name',
        linkLabel: 'pickUpName',
      },
      {
        defaultValue: '-',
        inputType: TABLE_INPUT_TYPES.text,
        style: { marginTop: '6px' },
        label: 'Pick Up Address',
        name: 'pickUpAddress2',
        placeholder: 'Enter Pick Up Address',
        isGooglePlaceInput: true,
        render: (googleAddress: string, defaultAddress: any) => {
          return !googleAddress || googleAddress === '-' ? defaultAddress : googleAddress;
        },
        isShowAsterisk: true,
        isDisabled: false,
        isAddress: true,
      },
      {
        defaultValue: '-',
        style: { marginTop: '20px' },
        alignToLeft: true,
        skipIfInput: true,
      },
      {
        defaultValue: '-',
        inputType: TABLE_INPUT_TYPES.text,
        style: { marginTop: '6px' },
        label: 'Contact Name',
        name: 'contactName2',
        placeholder: 'Enter Contact Name',
      },
      {
        defaultValue: '-',
        inputType: TABLE_INPUT_TYPES.text,
        style: { marginTop: '6px' },
        label: 'Contact Number',
        name: 'contactNumber2',
        placeholder: 'Enter Contact Number',
      },
      {
        inputType: TABLE_INPUT_TYPES.text,
        label: 'Transporting to',
        name: '',
        placeholder: '',
        bold: true,
        style: { marginTop: '20px' },
        skipIfInput: true,
      },
      {
        defaultValue: '-',
        inputType: TABLE_INPUT_TYPES.text,
        style: { marginTop: '6px' },
        label: 'Drop Off Name',
        name: 'dropOffName2',
        placeholder: 'Enter Drop Off Name',
        linkLabel: 'dropOffName',
      },
      {
        defaultValue: '-',
        inputType: TABLE_INPUT_TYPES.text,
        style: { marginTop: '6px' },
        label: 'Drop Off Address',
        name: 'dropOffAddress2',
        placeholder: 'Enter Drop Off Address',
        isGooglePlaceInput: true,
        render: (googleAddress: string, defaultAddress: any) => {
          return !googleAddress || googleAddress === '-' ? defaultAddress : googleAddress;
        },
        isShowAsterisk: true,
        isDisabled: false,
        isAddress: true,
      },
      {
        defaultValue: '-',
        inputType: TABLE_INPUT_TYPES.textarea,
        style: { marginTop: '20px' },
        label: 'Additional Notes',
        name: 'notes2',
        placeholder: 'Enter Additional Notes',
      },
      {
        defaultValue: '-',
        style: { marginTop: '20px' },
        render: () => {
          return '* Fields filled in by the recipient';
        },
        alignToLeft: true,
        skipIfInput: true,
      },
    ],
  },
  'Vegas Limo Transfer Arrival':
    [
      {
        defaultValue: '-',
        inputType: TABLE_INPUT_TYPES.text,
        label: 'Vendor Name',
        name: 'vendorName',
        placeholder: 'Enter Vendor Name',
        bold: true,
        style: { marginTop: '20px' },
        showMoreButton: true,
      },
      {
        cartInfo: true,
        vendorPhone: {
          defaultValue: '-',
          inputType: TABLE_INPUT_TYPES.phoneNumber,
          style: { marginTop: '6px', marginLeft: '0.25rem' },
          label: 'Vendor Phone',
          name: 'vendorPhone',
          placeholder: 'Enter Vendor Phone',
        },
      },
      {
        defaultValue: '-',
        inputType: TABLE_INPUT_TYPES.datetime,
        label: 'Pick Up At',
        name: 'pickUp',
        placeholder: 'Enter Pick Up Time',
        bold: true,
        style: { marginTop: '20px' },
        render: (date: Date) => {
          if (!new Date(date).getTime()) {
            return '-';
          }
          return moment(date).format('MMMM Do YYYY, h:mm a');
        },
      },
      {
        defaultValue: '-',
        inputType: TABLE_INPUT_TYPES.text,
        style: { marginTop: '6px' },
        label: 'Pick Up Name',
        name: 'pickUpName',
        placeholder: 'Enter Pick Up Name',
        isShowAsterisk: true,
        linkLabel: 'pickUpName',
      },
      {
        defaultValue: '-',
        inputType: TABLE_INPUT_TYPES.text,
        style: { marginTop: '6px' },
        label: 'Pick Up Address',
        name: 'pickUpAddress',
        placeholder: 'Enter Pick Up Address',
        isGooglePlaceInput: true,
        render: (googleAddress: string, defaultAddress: any) => {
          return !googleAddress || googleAddress === '-' ? defaultAddress : googleAddress;
        },
        isShowAsterisk: true,
        isDisabled: false,
        isAddress: true,
      },
      {
        defaultValue: '-',
        style: { marginTop: '20px' },
        alignToLeft: true,
        skipIfInput: true,
      },
      {
        defaultValue: '-',
        inputType: TABLE_INPUT_TYPES.text,
        style: { marginTop: '6px' },
        label: 'Contact Name',
        name: 'contactName',
        placeholder: 'Enter Contact Name',
        isShowAsterisk: true,
      },
      {
        defaultValue: '-',
        inputType: TABLE_INPUT_TYPES.text,
        style: { marginTop: '6px' },
        label: 'Contact Number',
        name: 'contactNumber',
        placeholder: 'Enter Contact Number',
        isShowAsterisk: true,
      },
      {
        inputType: TABLE_INPUT_TYPES.text,
        label: 'Transporting to',
        name: '',
        placeholder: '',
        bold: true,
        style: { marginTop: '20px' },
        skipIfInput: true,
      },
      {
        defaultValue: '-',
        inputType: TABLE_INPUT_TYPES.text,
        style: { marginTop: '6px' },
        label: 'Drop Off Name',
        name: 'dropOffName',
        placeholder: 'Enter Drop Off Name',
        isShowAsterisk: true,
        linkLabel: 'dropOffName',
      },
      {
        defaultValue: '-',
        inputType: TABLE_INPUT_TYPES.text,
        style: { marginTop: '6px' },
        label: 'Drop Off Address',
        name: 'dropOffAddress',
        placeholder: 'Enter Drop Off Address',
        isGooglePlaceInput: true,
        render: (googleAddress: string, defaultAddress: any) => {
          return !googleAddress || googleAddress === '-' ? defaultAddress : googleAddress;
        },
        isShowAsterisk: true,
        isDisabled: false,
        isAddress: true,
      },
      {
        defaultValue: '-',
        style: { marginTop: '6px' },
        label: 'Celebration',
        name: 'celebration',
        skipIfInput: true,
        isShowAsterisk: true,
      },
      {
        defaultValue: '-',
        inputType: TABLE_INPUT_TYPES.textarea,
        style: { marginTop: '20px' },
        label: 'Additional Notes',
        name: 'notes',
        placeholder: 'Enter Additional Notes',
      },
      {
        defaultValue: '-',
        style: { marginTop: '20px' },
        render: () => {
          return '* Fields filled in by the recipient';
        },
        alignToLeft: true,
        skipIfInput: true,
      },
    ],
  'Vegas Limo Transfer Departure':
    [
      {
        defaultValue: '-',
        inputType: TABLE_INPUT_TYPES.text,
        label: 'Vendor Name',
        name: 'vendorName',
        placeholder: 'Enter Vendor Name',
        bold: true,
        style: { marginTop: '20px' },
        showMoreButton: true,
      },
      {
        cartInfo: true,
        vendorPhone: {
          defaultValue: '-',
          inputType: TABLE_INPUT_TYPES.phoneNumber,
          style: { marginTop: '6px', marginLeft: '0.25rem' },
          label: 'Vendor Phone',
          name: 'vendorPhone',
          placeholder: 'Enter Vendor Phone',
        },
      },
      {
        defaultValue: '-',
        inputType: TABLE_INPUT_TYPES.datetime,
        label: 'Pick Up At',
        name: 'pickUp',
        placeholder: 'Enter Pick Up Time',
        bold: true,
        style: { marginTop: '20px' },
        render: (date: Date) => {
          if (!new Date(date).getTime()) {
            return '-';
          }
          return moment(date).format('MMMM Do YYYY, h:mm a');
        },
      },
      {
        defaultValue: '-',
        inputType: TABLE_INPUT_TYPES.text,
        style: { marginTop: '6px' },
        label: 'Pick Up Name',
        name: 'pickUpName',
        placeholder: 'Enter Pick Up Name',
        linkLabel: 'pickUpName',
      },
      {
        defaultValue: '-',
        inputType: TABLE_INPUT_TYPES.text,
        style: { marginTop: '6px' },
        label: 'Pick Up Address',
        name: 'pickUpAddress',
        placeholder: 'Enter Pick Up Address',
        isGooglePlaceInput: true,
        render: (googleAddress: string, defaultAddress: any) => {
          return !googleAddress || googleAddress === '-' ? defaultAddress : googleAddress;
        },
        isShowAsterisk: true,
        isDisabled: false,
        isAddress: true,
      },
      {
        defaultValue: '-',
        style: { marginTop: '20px' },
        alignToLeft: true,
        skipIfInput: true,
      },
      {
        defaultValue: '-',
        inputType: TABLE_INPUT_TYPES.text,
        style: { marginTop: '6px' },
        label: 'Contact Name',
        name: 'contactName',
        placeholder: 'Enter Contact Name',
      },
      {
        defaultValue: '-',
        inputType: TABLE_INPUT_TYPES.text,
        style: { marginTop: '6px' },
        label: 'Contact Number',
        name: 'contactNumber',
        placeholder: 'Enter Contact Number',
      },
      {
        inputType: TABLE_INPUT_TYPES.text,
        label: 'Transporting to',
        name: '',
        placeholder: '',
        bold: true,
        style: { marginTop: '20px' },
        skipIfInput: true,
      },
      {
        defaultValue: '-',
        inputType: TABLE_INPUT_TYPES.text,
        style: { marginTop: '6px' },
        label: 'Drop Off Name',
        name: 'dropOffName',
        placeholder: 'Enter Drop Off Name',
        linkLabel: 'dropOffName',
      },
      {
        defaultValue: '-',
        inputType: TABLE_INPUT_TYPES.text,
        style: { marginTop: '6px' },
        label: 'Drop Off Address',
        name: 'dropOffAddress',
        placeholder: 'Enter Drop Off Address',
        isGooglePlaceInput: true,
        render: (googleAddress: string, defaultAddress: any) => {
          return !googleAddress || googleAddress === '-' ? defaultAddress : googleAddress;
        },
        isShowAsterisk: true,
        isDisabled: false,
        isAddress: true,
      },
      {
        defaultValue: '-',
        inputType: TABLE_INPUT_TYPES.textarea,
        style: { marginTop: '20px' },
        label: 'Additional Notes',
        name: 'notes',
        placeholder: 'Enter Additional Notes',
      },
      {
        defaultValue: '-',
        style: { marginTop: '20px' },
        render: () => {
          return '* Fields filled in by the recipient';
        },
        alignToLeft: true,
        skipIfInput: true,
      },
    ],
  "Airport Transfer": {
    transfer1: [
      {
        defaultValue: "-",
        inputType: TABLE_INPUT_TYPES.text,
        label: "Vendor Name",
        name: "vendorName1",
        placeholder: "Enter Vendor Name",
        bold: true,
        showMoreButton: true,
        icon: carIcon,
        skipIfInput: true,
        checkPending: true,
      },
      {
        defaultValue: '-',
        inputType: TABLE_INPUT_TYPES.text,
        style: { marginTop: '6px' },
        label: 'Contact Name',
        name: 'contactName1',
        placeholder: 'Enter Contact Name',
        isShowAsterisk: true,
      },
      {
        defaultValue: "-",
        inputType: TABLE_INPUT_TYPES.datetime,
        label: "Pick Up At",
        name: "date1",
        placeholder: "Enter Pick Up Time",
        bold: true,
        render: (date: Date) => {
          if (!new Date(date).getTime()) {
            return "-";
          }
          return moment(date).format("hh:mm a");
        },
        icon: mapIcon,
        checkIfNotNull: true,
      },
      {
        defaultValue: "-",
        inputType: TABLE_INPUT_TYPES.text,
        style: { marginTop: "6px" },
        label: "Airport",
        name: "airportName1",
        placeholder: "Enter Airport Name",
        checkIfNotNull: true,
      },
      {
        defaultValue: "-",
        inputType: TABLE_INPUT_TYPES.text,
        style: { marginTop: "6px" },
        label: "Confirmation",
        name: "confirmation1",
        placeholder: "Enter Confirmation Number",
        skipIfInput: true,
        checkPending: true,
      },
      {
        defaultValue: "-",
        inputType: TABLE_INPUT_TYPES.text,
        style: { marginTop: "6px" },
        label: (flightNumber: string | null, showDefault: boolean) => {
          if (!showDefault) {
            return `Flight Number #${flightNumber} Arriving`;
          }

          return "Flight Number";
        },
        render: (flightNumber: string, date: Date) => {
          if (!new Date(date).getTime()) {
            return "-";
          }
          return moment(date, "YYYY-MM-DDThh:mm").format("hh:mm a");
        },
        showDataFrom: "arrivalTime",
        name: "flightNumber",
        placeholder: "Enter Flight Number",
        checkIfNotNull: true,
      },
      {
        defaultValue: "-",
        inputType: TABLE_INPUT_TYPES.time,
        style: { marginTop: "6px" },
        label: "Arrival Time",
        name: "arrivalTime",
        placeholder: "Enter Arrival Time",
        skipInCart: true,
        skipIfInput: true,
        checkPending: true,
      },
      {
        inputType: TABLE_INPUT_TYPES.text,
        label: "Transporting to",
        name: "",
        placeholder: "",
        bold: true,
        style: { marginTop: "20px" },
        skipIfInput: true,
        icon: mapIcon,
      },
      {
        defaultValue: "-",
        inputType: TABLE_INPUT_TYPES.text,
        style: { marginTop: "6px" },
        label: "Hotel Name",
        name: "hotelName1",
        placeholder: "Enter Hotel Name",
        checkIfNotNull: true,
      },
      {
        defaultValue: "-",
        inputType: TABLE_INPUT_TYPES.text,
        style: { marginTop: "6px" },
        label: "Hotel Address",
        name: "hotelAddress1",
        placeholder: "Enter Hotel Address",
        checkIfNotNull: true,
        linkLabel: "hotelPlaceName",
        linkValue: "hotelPlaceLink",
        isAddress: true,
      },
      {
        defaultValue: "-",
        inputType: TABLE_INPUT_TYPES.textarea,
        style: { marginTop: "20px" },
        label: "Additional Notes",
        name: "notes1",
        placeholder: "Enter Additional Notes",
        icon: pencilIcon,
        // hideIfEmpty: true,
      },
    ],
    transfer2: [
      {
        defaultValue: "-",
        inputType: TABLE_INPUT_TYPES.text,
        label: "Vendor Name",
        name: "vendorName2",
        placeholder: "Enter Vendor Name",
        bold: true,
        showMoreButton: true,
        icon: carIcon,
        skipIfInput: true,
        checkPending: true,
      },
      {
        defaultValue: '-',
        inputType: TABLE_INPUT_TYPES.text,
        style: { marginTop: '6px' },
        label: 'Contact Name',
        name: 'contactName2',
        placeholder: 'Enter Contact Name',
        isShowAsterisk: true,
      },
      {
        defaultValue: "-",
        inputType: TABLE_INPUT_TYPES.datetime,
        label: "Pick Up At",
        name: "date2",
        placeholder: "Enter Pick Up Time",
        bold: true,
        render: (date: Date) => {
          if (!new Date(date).getTime()) {
            return "-";
          }
          return moment(date).format("hh:mm a");
        },
        skipIfInput: true,
        icon: mapIcon,
        checkPending: true,
      },
      {
        defaultValue: "-",
        inputType: TABLE_INPUT_TYPES.text,
        style: { marginTop: "6px" },
        label: "Hotel Name",
        name: "hotelName2",
        placeholder: "Enter Hotel Name",
        checkIfNotNull: true,
        // isLink: true,
      },
      {
        defaultValue: "-",
        inputType: TABLE_INPUT_TYPES.text,
        style: { marginTop: "6px" },
        label: "Hotel Address",
        name: "hotelAddress2",
        placeholder: "Enter Hotel Address",
        checkIfNotNull: true,
        linkLabel: "hotelPlaceName",
        linkValue: "hotelPlaceLink",
        isAddress: true,
      },
      {
        defaultValue: "-",
        inputType: TABLE_INPUT_TYPES.text,
        style: { marginTop: "6px" },
        label: "Confirmation",
        name: "confirmation2",
        placeholder: "Enter Confirmation",
        skipIfInput: true,
        checkPending: true,
      },
      {
        inputType: TABLE_INPUT_TYPES.text,
        label: "Transporting to",
        name: "",
        placeholder: "",
        bold: true,
        style: { marginTop: "20px" },
        skipIfInput: true,
        icon: mapIcon,
      },
      {
        defaultValue: "-",
        inputType: TABLE_INPUT_TYPES.text,
        style: { marginTop: "6px" },
        label: (flightNumber: string, showDefault: string) => {
          if (!showDefault) {
            return `Flight Number #${flightNumber} * Departuring`;
          }

          return "Flight Number";
        },
        render: (flightNumber: string, date: string) => {
          if (!new Date(date).getTime()) {
            return "-";
          }

          return moment(date, "YYYY-MM-DDThh:mm").format("hh:mm a");
        },
        showDataFrom: "departureFlightDate",
        name: "departureFlightNumber",
        placeholder: "Enter Flight Number",
        isShowAsterisk: true,
      },
      {
        defaultValue: "-",
        inputType: TABLE_INPUT_TYPES.text,
        style: { marginTop: "6px" },
        label: "Airport",
        name: "airportName2",
        placeholder: "Enter Airport Name",
        checkIfNotNull: true,
      },
      {
        defaultValue: "-",
        inputType: TABLE_INPUT_TYPES.text,
        style: { marginTop: "6px" },
        label: "Airport Address",
        name: "airportPlaceLink",
        linkLabel: "airportPlaceName",
        placeholder: "Enter Airport Address",
        skipIfInput: true,
        checkPending: true,
        isLink: true,
      },
      {
        defaultValue: "-",
        inputType: TABLE_INPUT_TYPES.textarea,
        style: { marginTop: "20px" },
        label: "Additional Notes",
        name: "notes2",
        placeholder: "Enter Additional Notes",
        icon: pencilIcon,
        // hideIfEmpty: true,
      },
    ],
  },
  "Airport Transfer Arrival":
    [
      {
        defaultValue: "-",
        inputType: TABLE_INPUT_TYPES.text,
        label: "Vendor Name",
        name: "vendorName",
        placeholder: "Enter Vendor Name",
        bold: true,
        showMoreButton: true,
        icon: carIcon,
        skipIfInput: true,
        checkPending: true,
      },
      {
        defaultValue: '-',
        inputType: TABLE_INPUT_TYPES.text,
        style: { marginTop: '6px' },
        label: 'Contact Name',
        name: 'contactName',
        placeholder: 'Enter Contact Name',
        isShowAsterisk: true,
      },
      {
        defaultValue: "-",
        inputType: TABLE_INPUT_TYPES.datetime,
        label: "Pick Up At",
        name: "date",
        placeholder: "Enter Pick Up Time",
        bold: true,
        render: (date: Date) => {
          if (!new Date(date).getTime()) {
            return "-";
          }
          return moment(date).format("hh:mm a");
        },
        icon: mapIcon,
        checkIfNotNull: true,
      },
      {
        defaultValue: "-",
        inputType: TABLE_INPUT_TYPES.text,
        style: { marginTop: "6px" },
        label: "Airport",
        name: "airportName",
        placeholder: "Enter Airport Name",
        checkIfNotNull: true,
      },
      {
        defaultValue: "-",
        inputType: TABLE_INPUT_TYPES.text,
        style: { marginTop: "6px" },
        label: "Confirmation",
        name: "confirmation",
        placeholder: "Enter Confirmation Number",
        skipIfInput: true,
        checkPending: true,
      },
      {
        defaultValue: "-",
        inputType: TABLE_INPUT_TYPES.text,
        style: { marginTop: "6px" },
        label: (flightNumber: string | null, showDefault: boolean) => {
          if (!showDefault) {
            return `Flight Number #${flightNumber} Arriving`;
          }

          return "Flight Number";
        },
        render: (flightNumber: string, date: Date) => {
          if (!new Date(date).getTime()) {
            return "-";
          }
          return moment(date, "YYYY-MM-DDThh:mm").format("hh:mm a");
        },
        showDataFrom: "arrivalTime",
        name: "flightNumber",
        placeholder: "Enter Flight Number",
        checkIfNotNull: true,
      },
      {
        defaultValue: "-",
        inputType: TABLE_INPUT_TYPES.time,
        style: { marginTop: "6px" },
        label: "Arrival Time",
        name: "arrivalTime",
        placeholder: "Enter Arrival Time",
        skipInCart: true,
        skipIfInput: true,
        checkPending: true,
      },
      {
        inputType: TABLE_INPUT_TYPES.text,
        label: "Transporting to",
        name: "",
        placeholder: "",
        bold: true,
        style: { marginTop: "20px" },
        skipIfInput: true,
        icon: mapIcon,
      },
      {
        defaultValue: "-",
        inputType: TABLE_INPUT_TYPES.text,
        style: { marginTop: "6px" },
        label: "Hotel Name",
        name: "hotelName",
        placeholder: "Enter Hotel Name",
        checkIfNotNull: true,
      },
      {
        defaultValue: "-",
        inputType: TABLE_INPUT_TYPES.text,
        style: { marginTop: "6px" },
        label: "Hotel Address",
        name: "hotelAddress",
        placeholder: "Enter Hotel Address",
        checkIfNotNull: true,
        linkLabel: "hotelPlaceName",
        linkValue: "hotelPlaceLink",
        isAddress: true,
      },
      {
        defaultValue: "-",
        inputType: TABLE_INPUT_TYPES.textarea,
        style: { marginTop: "20px" },
        label: "Additional Notes",
        name: "notes",
        placeholder: "Enter Additional Notes",
        icon: pencilIcon,
        // hideIfEmpty: true,
      },
    ],
  "Airport Transfer Departure":[
    {
      defaultValue: "-",
      inputType: TABLE_INPUT_TYPES.text,
      label: "Vendor Name",
      name: "vendorName",
      placeholder: "Enter Vendor Name",
      bold: true,
      showMoreButton: true,
      icon: carIcon,
      skipIfInput: true,
      checkPending: true,
    },
    {
      defaultValue: '-',
      inputType: TABLE_INPUT_TYPES.text,
      style: { marginTop: '6px' },
      label: 'Contact Name',
      name: 'contactName',
      placeholder: 'Enter Contact Name',
      isShowAsterisk: true,
    },
    {
      defaultValue: "-",
      inputType: TABLE_INPUT_TYPES.datetime,
      label: "Pick Up At",
      name: "date",
      placeholder: "Enter Pick Up Time",
      bold: true,
      render: (date: Date) => {
        if (!new Date(date).getTime()) {
          return "-";
        }
        return moment(date).format("hh:mm a");
      },
      skipIfInput: true,
      icon: mapIcon,
      checkPending: true,
    },
    {
      defaultValue: "-",
      inputType: TABLE_INPUT_TYPES.text,
      style: { marginTop: "6px" },
      label: "Hotel Name",
      name: "hotelName",
      placeholder: "Enter Hotel Name",
      checkIfNotNull: true,
      // isLink: true,
    },
    {
      defaultValue: "-",
      inputType: TABLE_INPUT_TYPES.text,
      style: { marginTop: "6px" },
      label: "Hotel Address",
      name: "hotelAddress",
      placeholder: "Enter Hotel Address",
      checkIfNotNull: true,
      linkLabel: "hotelPlaceName",
      linkValue: "hotelPlaceLink",
      isAddress: true,
    },
    {
      defaultValue: "-",
      inputType: TABLE_INPUT_TYPES.text,
      style: { marginTop: "6px" },
      label: "Confirmation",
      name: "confirmation",
      placeholder: "Enter Confirmation",
      skipIfInput: true,
      checkPending: true,
    },
    {
      inputType: TABLE_INPUT_TYPES.text,
      label: "Transporting to",
      name: "",
      placeholder: "",
      bold: true,
      style: { marginTop: "20px" },
      skipIfInput: true,
      icon: mapIcon,
    },
    {
      defaultValue: "-",
      inputType: TABLE_INPUT_TYPES.text,
      style: { marginTop: "6px" },
      label: (flightNumber: string, showDefault: string) => {
        if (!showDefault) {
          return `Flight Number #${flightNumber} * Departuring`;
        }

        return "Flight Number";
      },
      render: (flightNumber: string, date: string) => {
        if (!new Date(date).getTime()) {
          return "-";
        }

        return moment(date, "YYYY-MM-DDThh:mm").format("hh:mm a");
      },
      showDataFrom: "departureFlightDate",
      name: "departureFlightNumber",
      placeholder: "Enter Flight Number",
      isShowAsterisk: true,
    },
    {
      defaultValue: "-",
      inputType: TABLE_INPUT_TYPES.text,
      style: { marginTop: "6px" },
      label: "Airport",
      name: "airportName",
      placeholder: "Enter Airport Name",
      checkIfNotNull: true,
    },
    {
      defaultValue: "-",
      inputType: TABLE_INPUT_TYPES.text,
      style: { marginTop: "6px" },
      label: "Airport Address",
      name: "airportPlaceLink",
      linkLabel: "airportPlaceName",
      placeholder: "Enter Airport Address",
      skipIfInput: true,
      checkPending: true,
      isLink: true,
    },
    {
      defaultValue: "-",
      inputType: TABLE_INPUT_TYPES.textarea,
      style: { marginTop: "20px" },
      label: "Additional Notes",
      name: "notes",
      placeholder: "Enter Additional Notes",
      icon: pencilIcon,
      // hideIfEmpty: true,
    },
  ],
  "CA Gift Card": [
    {
      label: "",
      name: "description",
      skipIfInput: true,
      defaultValue: "-",
      alignToLeft: true,
      icon: copyIcon,
      hideIfEmpty: true,
    },
    {
      defaultValue: "-",
      inputType: TABLE_INPUT_TYPES.textarea,
      style: { marginTop: "20px" },
      label: "Additional Notes",
      name: "notes",
      placeholder: "Enter Additional Notes",
      icon: pencilIcon,
      hideIfEmpty: true,
    },
  ],
  Parking: [
    {
      defaultValue: "-",
      inputType: TABLE_INPUT_TYPES.textarea,
      style: { marginTop: "6px" },
      label: "Description",
      name: "description",
      alignToLeft: true,
      placeholder: "Enter Description",
      icon: copyIcon,
      hideIfEmpty: true,
    },
    {
      defaultValue: "-",
      style: { marginTop: "20px" },
      label: "Additional Notes",
      name: "notes",
      placeholder: "Enter Additional Notes",
      icon: pencilIcon,
      hideIfEmpty: true,
    },
  ],
  Tailgate: [
    {
      defaultValue: "-",
      inputType: TABLE_INPUT_TYPES.textarea,
      style: { marginTop: "6px" },
      label: "Description",
      name: "description",
      alignToLeft: true,
      placeholder: "Enter Description",
      icon: copyIcon,
      hideIfEmpty: true,
    },
    {
      defaultValue: "-",
      style: { marginTop: "20px" },
      label: "Additional Notes",
      name: "notes",
      placeholder: "Enter Additional Notes",
      icon: pencilIcon,
      hideIfEmpty: true,
    },
  ],
  "City Pass": [
    {
      isTextContainsLink: true,
      name: "description",
      label: "",
      // render: (link: string) => {
      //   return `These City Pass tickets may be presented at each venue right from your Mobile device. Please plan ahead as some venues require reservations in advance. ${
      //     link
      //       ? `You may view the City Pass Guide <a href="${
      //           link && (link.includes("http://") || link.includes("https://")) ? link : "//" + link
      //         }" target="_blank" >HERE</a>`
      //       : ""
      //   }`;
      // },
      skipIfInput: true,
      defaultValue: "-",
      alignToLeft: true,
      icon: copyIcon,
    },
    // {
    //   defaultValue: "-",
    //   style: { marginTop: "20px" },
    //   label: "Attendees",
    //   bold: true,
    //   showMoreButton: true,
    //   name: "attendeeNames",
    //   icon: "fa fa-lg fa-regular  fa-user",
    //   hideIfEmpty: true,
    // },
    // {
    //   cartInfo: true,
    //   checkPending: true,
    //   attendeeName: {
    //     defaultValue: "-",
    //     inputType: TABLE_INPUT_TYPES.phoneNumber,
    //     style: { marginTop: "6px", marginLeft: "0.25rem" },
    //     label: "Attendee Name",
    //     name: "attendeeName",
    //     skipIfInput: true,
    //     checkPending: true,
    //   },
    // },
    {
      defaultValue: "-",
      style: { marginTop: "20px" },
      label: "Additional Notes",
      name: "notes",
      placeholder: "Enter Additional Notes",
      icon: pencilIcon,
      hideIfEmpty: true,
    },
  ],
  "Rideshare Pass": [
    {
      isTextContainsLink: true,
      name: "description",
      label: "",
      skipIfInput: true,
      defaultValue: "-",
      alignToLeft: true,
      icon: copyIcon,
      hideIfEmpty: true,
    },
    {
      defaultValue: "-",
      style: { marginTop: "20px" },
      label: "Additional Notes",
      name: "notes",
      placeholder: "Enter Additional Notes",
      icon: pencilIcon,
      hideIfEmpty: true,
    },
  ],
  Fanatics: [
    {
      name: "description",
      alignToLeft: true,
      icon: copyIcon,
      hideIfEmpty: true,
    },
    {
      defaultValue: "-",
      style: { marginTop: "20px" },
      label: "Additional Notes",
      name: "notes",
      icon: pencilIcon,
      hideIfEmpty: true,
    },
  ],
  "Fanatics Credit": [
    {
      label: "Description",
      name: "description",
      isTextContainsLink: true,
      skipIfInput: true,
      defaultValue: "-",
      alignToLeft: true,
      icon: copyIcon,
      style: { marginTop: "6px" },
      hideIfEmpty: true,
    },
    {
      defaultValue: '-',
      inputType: TABLE_INPUT_TYPES.text,
      style: { marginTop: '6px' },
      label: 'URL',
      name: 'eLink',
      placeholder: 'Enter URL',
      isLink: true,
      hideIfEmpty: true,
    },
    {
      defaultValue: "-",
      style: { marginTop: "6px" },
      label: "eGift code",
      name: "eGiftCode",
      placeholder: "Enter eGift code",
      icon: giftCardIcon,
      hideIfEmpty: true,
    },
    {
      defaultValue: "-",
      style: { marginTop: "6px" },
      label: "PIN",
      name: "PIN",
      placeholder: "Enter PIN",
      hideIfEmpty: true,
    },
    {
      defaultValue: "-",
      style: { marginTop: "20px" },
      label: "Additional Notes",
      name: "notes",
      placeholder: "Enter Additional Notes",
      icon: pencilIcon,
      hideIfEmpty: true,
    },
  ],
  GiftCard: [
    {
      skipIfInput: true,
      defaultValue: "-",
      alignToLeft: true,
      icon: copyIcon,
      name: "description",
      hideIfEmpty: true,
    },
    {
      defaultValue: "-",
      style: { marginTop: "20px" },
      label: "Additional Notes",
      name: "notes",
      placeholder: "Enter Additional Notes",
      icon: pencilIcon,
      hideIfEmpty: true,
    },
  ],
  Memorabilia: [
    {
      defaultValue: "-",
      style: { marginTop: "20px" },
      label: "Additional Notes",
      name: "notes",
      placeholder: "Enter Additional Notes",
      icon: pencilIcon,
      hideIfEmpty: true,
    },
  ],
  "MLB Card": [
    {
      name: "description",
      isTextContainsLink: true,
      label: "",
      alignToLeft: true,
      defaultValue: "-",
      icon: copyIcon,
      style: { marginTop: "6px" },
      hideIfEmpty: true,
    },
    {
      defaultValue: "-",
      style: { marginTop: "6px" },
      label: "eGift code",
      name: "eGiftCode",
      placeholder: "Enter eGift code",
      hideIfEmpty: true,
    },
    {
      defaultValue: "-",
      style: { marginTop: "6px" },
      label: "PIN",
      name: "PIN",
      placeholder: "Enter PIN",
      hideIfEmpty: true,
    },
    {
      defaultValue: "-",
      style: { marginTop: "20px" },
      label: "Additional Notes",
      name: "notes",
      placeholder: "Enter Additional Notes",
      icon: pencilIcon,
      hideIfEmpty: true,
    },
  ],
  "NBA Card": [
    {
      name: "description",
      isTextContainsLink: true,
      skipIfInput: true,
      defaultValue: "-",
      alignToLeft: true,
      icon: copyIcon,
      style: { marginTop: "6px" },
      hideIfEmpty: true,
    },
    {
      defaultValue: "-",
      style: { marginTop: "6px" },
      label: "eGift code",
      name: "eGiftCode",
      placeholder: "Enter eGift code",
      hideIfEmpty: true,
    },
    {
      defaultValue: "-",
      style: { marginTop: "6px" },
      label: "PIN",
      name: "PIN",
      placeholder: "Enter PIN",
      hideIfEmpty: true,
    },
    {
      defaultValue: "-",
      style: { marginTop: "20px" },
      label: "Additional Notes",
      name: "notes",
      placeholder: "Enter Additional Notes",
      icon: pencilIcon,
      hideIfEmpty: true,
    },
  ],
  "NHL Card": [
    {
      name: "description",
      isTextContainsLink: true,
      skipIfInput: true,
      defaultValue: "-",
      alignToLeft: true,
      icon: copyIcon,
      style: { marginTop: "6px" },
      hideIfEmpty: true,
    },
    {
      defaultValue: "-",
      style: { marginTop: "6px" },
      label: "eGift code",
      name: "eGiftCode",
      placeholder: "Enter eGift code",
      hideIfEmpty: true,
    },
    {
      defaultValue: "-",
      style: { marginTop: "6px" },
      label: "PIN",
      name: "PIN",
      placeholder: "Enter PIN",
      hideIfEmpty: true,
    },
    {
      defaultValue: "-",
      style: { marginTop: "20px" },
      label: "Additional Notes",
      name: "notes",
      placeholder: "Enter Additional Notes",
      icon: pencilIcon,
      hideIfEmpty: true,
    },
  ],
  "Multi-day Gift Card": [
    {
      name: "description",
      isTextContainsLink: true,
      skipIfInput: true,
      defaultValue: "-",
      alignToLeft: true,
      icon: copyIcon,
      style: { marginTop: "6px" },
      hideIfEmpty: true,
    },
    {
      defaultValue: "-",
      style: { marginTop: "6px" },
      label: "eGift code",
      name: "eGiftCode",
      placeholder: "Enter eGift code",
      hideIfEmpty: true,
    },
    {
      defaultValue: "-",
      style: { marginTop: "6px" },
      label: "PIN",
      name: "PIN",
      placeholder: "Enter PIN",
      hideIfEmpty: true,
    },
    {
      defaultValue: "-",
      style: { marginTop: "20px" },
      label: "Additional Notes",
      name: "notes",
      placeholder: "Enter Additional Notes",
      icon: pencilIcon,
      hideIfEmpty: true,
    },
  ],
  Outsourced: [
    {
      defaultValue: "-",
      name: "description",
      alignToLeft: true,
      icon: copyIcon,
      hideIfEmpty: true,
    },
    {
      defaultValue: "-",
      style: { marginTop: "20px" },
      label: "Additional Notes",
      name: "notes",
      placeholder: "Enter Additional Notes",
      icon: pencilIcon,
      hideIfEmpty: true,
    },
  ],
} as const;

export const CART_FILTERS = {
  showAll: "Show All",
  airportTransfer: "Airport Transfers",
  vegasLimoTransfer: "Vegas Limo Transfers",
  hotel: "Hotel",
  transportation: "Transportation",
  hospitality: "Hospitality",
  event: "Event",
  merchandise: "Merchandise",
  additionalMerch: "Additional Merch",
  virtualLesson: "Virtual Lesson",
  eventCredits: "Event Credits",
  cityPass: "City Pass",
  credit: "Credit",
  merchandiseCredit: "Merchandise Credit",
  meetGreet: "Meet & Greet",
  memorabilia: "Memorabilia",
  other: "Other",
  parking: "Parking",
  tailgate: "Tailgate",
  VRHeadset: "VR Headset",
  giftBundle: "Gift Bundle",
  rideSharePass: 'Rideshare Pass',
  replacementGifts: 'Replacement Gifts',
};

export const CARTS_BY_FILTERS = {
  airportTransfer: ["Airport Transfers", "Airport Transfer Arrival", "Airport Transfer Departure"],
  hotel: ["Hotel Details"],
  transportation: ["Transportation Services"],
  hospitality: ["Pre-show Hospitality"],
  event: ["Event Details"],
  merchandise: ["Merchandise"],
  additionalMerch: ["Additional Merchandise"],
  virtualLesson: ["Virtual Lesson"],
  eventCredits: ["Food/Beverage/Merchandise Credits"],
  cityPass: ["City Pass Details"],
  credit: ["Credit"],
  merchandiseCredit: ["Merchandise Credit"],
  meetGreet: ["Meet & Greet"],
  memorabilia: ["Memorabilia"],
  other: ["Other"],
  parking: ["Parking"],
  tailgate: ["Tailgate"],
  VRHeadset: ["VR Headset"],
  giftBundle: ["Gift Bundle"],
  rideSharePass: ['Rideshare Pass'],
  replacementGifts: ['Replacement Gifts'],
};

export const EDITABLE_CART_TYPES = ["Airport Transfer", "Airport Transfer Arrival", "Airport Transfer Departure", "Vegas Limo Transfer", "Vegas Limo Transfer Arrival","Vegas Limo Transfer Departure", "Transportation Services"];

export const CARD_TYPES_TICKETS = [
  "Airport Transfer",
  "Airport Transfer Arrival",
  "Airport Transfer Departure",
  "Hotel",
  "Limo",
  "Dinner",
  "Ticket",
  "Gift Bundle",
  "CA Gift Card",
  "City Pass",
  "Rideshare Pass",
  "Fanatics",
  "Fanatics Credit",
  "GiftCard",
  "Memorabilia",
  "MLB Card",
  "Multi-day Gift Card",
  "NBA Card",
  "NHL Card",
  "Parking",
  "Tailgate",
  "Transfer",
  "Vegas Limo Transfer",
  "Vegas Limo Transfer Arrival",
  "Vegas Limo Transfer Departure",
  "Outsourced",
];

export const TABS_LABELS = {
  NEW: "NEW",
  NEEDS_FULFILLMENT: "NEEDS FULFILLMENT",
  NEEDS_DELIVERY: "NEEDS DELIVERY",
  PENDING_REC: "PENDING REC",
  COMPLETED: "COMPLETED",
  REJECTED: "REJECTED",
  VIEW_ALL: "ALL",
  VOID: "VOID",
  COMMISSION_REPORT: "COMMISSION REPORT",
  CATEGORY_REPORT: "CATEGORY REPORT",
  INVENTORY_DEPT_SALES_REPORT: "INVENTORY DEPT SALES REPORT",
  AFFILATE_REPORT: "AFFILATE REPORT",
  UNSENT_ORDER_REPORT: "UNSENT ORDER REPORT",
  DAILY_PRICING_REPORT: "DAILY PRICING REPORT",
  DAILY_INVENTORY_DEPT_REPORT: "DAILY INVENTORY DEPT REPORT",
  MANAGE_USERS: "MANAGE USERS",
  MANAGE_REPORT_RECIPIENTS: "MANAGE REPORT RECIPIENTS",
  GENERATE_REPORT: "GENERATE REPORT",
  MANAGE_USER_REPORTS: "MANAGE USER REPORTS",
  CLIENTS: "CLIENTS",
  RECIPIENTS: "RECIPIENTS",
  UPCOMING: "UPCOMING",
  ARCHIVE: "ARCHIVE",
};

export const TABS = {
  CLIENTS: [TABS_LABELS.CLIENTS, TABS_LABELS.RECIPIENTS],
  BROKER_DASHBOARD: [TABS_LABELS.UPCOMING, TABS_LABELS.ARCHIVE],
};

export const DEFAULT_CARD_CONFIG = [
  {
    label: "",
    name: "description",
    defaultValue: "-",
    inputType: "text",
    alignToLeft: true,
    icon: copyIcon,
    renderDescription: (defaultDescription: string, group: string) => {
      return defaultDescription;
    },
  },
];

export const TABLE_CONFIG = {
  BROKER_DASHBOARD: {
    invoiceId: {
      colTitle: "Invoice ID",
      key: "invoiceId",
      type: tableFieldTypes.number,
    },
    externalPo: {
      colTitle: "Order ID",
      key: "externalPo",
      type: tableFieldTypes.string,
    },
    saleDate: {
      colTitle: "Sale Date",
      key: "saleDate",
      type: tableFieldTypes.string,
    },
    client: {
      colTitle: "Client",
      key: "client",
      type: tableFieldTypes.string,
    },
    recipient: {
      colTitle: "Recipient",
      key: "recipientName",
      type: tableFieldTypes.string,
      render: (value: any) => {
        return value.recipientName;
      },
    },
    eventName: {
      colTitle: "Event Name",
      key: "eventName",
      type: tableFieldTypes.string,
    },
    venue: {
      colTitle: "Venue",
      key: "venue",
      type: tableFieldTypes.string,
    },
    eventDate: {
      colTitle: "Event Date",
      key: "eventDate",
      type: tableFieldTypes.string,
    },
    qty: {
      colTitle: "Qty",
      key: "qty",
      type: tableFieldTypes.number,
    },
    shipBy: {
      colTitle: "Ship By",
      key: "shipBy",
      type: tableFieldTypes.string,
    },
  },
  CLIENTS: {
    name: {
      colTitle: "Name",
      type: tableFieldTypes.string,
      key: "name",
      isSortable: true,
    },
    totalSales: {
      colTitle: "Total Sales",
      type: tableFieldTypes.price,
      key: "totalSales",
      isSortable: true,
    },
    packagesCount: {
      colTitle: "Invoice Count",
      type: tableFieldTypes.string,
      key: "packagesCount",
      isSortable: true,
    },
  },
  RECIPIENTS: {
    name: {
      colTitle: "Name",
      type: tableFieldTypes.string,
      key: "name",
      isSortable: true,
    },
    email: {
      colTitle: "Email",
      type: tableFieldTypes.string,
      key: "email",
      isSortable: true,
    },
    phoneNumber: {
      colTitle: "Phone Number",
      type: tableFieldTypes.string,
      key: "phoneNumber",
      isSortable: false,
    },
    totalSales: {
      colTitle: "Total Sales",
      type: tableFieldTypes.price,
      key: "totalSales",
      isSortable: true,
    },
    packagesCount: {
      colTitle: "Invoice Count",
      type: tableFieldTypes.string,
      key: "packagesCount",
      isSortable: true,
    },
  },
} as const;

export const FULLY_EDITABLE_CARDS = [
  "Meet & Greet",
  "Broadway Virtual Lesson",
  "Courtesy Credit",
  "Additional Gifts",
  "Gift",
  "Other",
  "VIP Club Access",
  "Virtual Lesson",
  "VR Headset",
  "Merchandise Credit",
  'Replacement Gifts',
];

export const editableCartTypes = [
  "Airport Transfer",
  "Airport Transfer Arrival",
  "Airport Transfer Departure",
  "Hotel",
  "Limo",
  "Dinner",
  "Ticket",
  "Gift Bundle",
  "CA Gift Card",
  "City Pass",
  "Rideshare Pass",
  "Fanatics",
  "Fanatics Credit",
  "GiftCard",
  "Memorabilia",
  "MLB Card",
  "Multi-day Gift Card",
  "NBA Card",
  "NHL Card",
  "Parking",
  "Tailgate",
  "Transfer",
  "Vegas Limo Transfer",
  "Vegas Limo Transfer Arrival",
  "Vegas Limo Transfer Departure",
  "Outsourced",
];

export const SERVICE_STATE = {
  DECLINED: "DECLINED",
  UNDECLINED: "UNDECLINED",
};

export const STAGE_ONE_CARDS = [
  "Airport Transfer",
  "Airport Transfer Arrival",
  "Airport Transfer Departure",
  "Limo",
  "City Pass",
  "Dinner",
  "Vegas Limo Transfer",
  "Vegas Limo Transfer Arrival",
  "Vegas Limo Transfer Departure",
  "Hotel",
];

export const cardGroupIcons: { [key: string]: string } = {
  'Ticket': ticketIcon,
  'Airport Transfer': airportIcon,
  "Airport Transfer Arrival": airportIcon,
  "Airport Transfer Departure": airportIcon,
  'Hotel': ticketIcon,
  'Limo': transportingIcon,
  'Dinner': utencilsIcon,
  'Gift Bundle':  giftIcon,
  'CA Gift Card':  giftIcon,
  'City Pass': buildingIcon,
  'Courtesy Credit': receiptIcon,
  'Fanatics': giftIcon,
  'Fanatics Credit': giftIcon,
  'Gift': giftIcon,
  'GiftCard': giftIcon,
  'Meet & Greet': handShakeIcon,
  'MeetGreet': handShakeIcon,
  'Meet and Greet': handShakeIcon,
  'Memorabilia': giftIcon,
  'MgmtFee': giftIcon,
  'MLB Card': giftIcon,
  'NBA Card': giftIcon,
  'NHL Card': giftIcon,
  'Other': dollarIcon,
  'Parking': parkingIcon,
  'Tailgate': partyPooperIcon,
  'Transfer': transportingIcon,
  'Vegas Limo Transfer': partyPooperIcon,
  'Vegas Limo Transfer Arrival': partyPooperIcon,
  'Vegas Limo Transfer Departure': partyPooperIcon,
  'VIP Club Access': utencilsIcon,
  'Additional Gifts': giftIcon,
  'Merchandise Credit': giftIcon,
  'Replacement Gifts': giftIcon,
  'Replacement Pass': giftIcon,
  'Outsourced': dollarIcon,
  'Multi-day Gift Card': giftIcon,
};
