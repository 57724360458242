import { useEffect, useState } from "react";
import { Row, Col, CardBody } from "reactstrap";
import { successMsg } from "../../cache/vars";
import { DECLINE_SERVICE } from "../../queries/vip-workflow";
import { EDITABLE_CART_TYPES, SERVICE_STATE } from "../../utils/constants";
import PendingLabel from "../labels/Pending";
import RenderCardBody from "./RenderCardBody";
import { useApolloClient } from "@apollo/client";
import StagedCardLayout from "../StagedCard/StagedCardLayout/StagedCardLayout";
import { cardGroupIcons } from "../../utils/constants"
import CardDeclineBtn from "../StagedCard/CardDeclineBtn/CardDeclineBtn";
import CardAction from "../StagedCard/CardAction/CardAction";
import { generateMailtoLink } from "../../utils/services";

import defaultIcon from '../../assets/img/icons/cards/handshake-icon.svg'
import helpIcon from "../../assets/img/icons/cards/help-icon.svg";


const PackageDetailsCard = ({
  isShowMissingInfoLabel,
  isShowDeclineServiceButton,
  cart,
  isMissingInfo,
  isPublished,
  isServiceDeclined,
  serviceDeclined,
  cardTitle,
  setModalType,
  setPackageDetailsId,
  setIsModalOpened,
  airportTransferType,
  setAirportTransferType,
  vegasLimoTransferType,
  setVegasLimoTransferType,
  isCartInfoOpenedId,
  handleOpenDetails,
  handleOpenLearnMore,
  isLearnMoreOpenedId,
  isInvoiceArchived,
  setPackageOrderWithDetails,
  parsedCardData,
  cardDate,
  cardTime,
}: any) => {
  const client = useApolloClient();
  
  const [emailLink, setEmailLink] = useState("");

  const handleDeclineClick = async () => {
    if (window.confirm("Are you sure you want to decline this service?")) {
      const { data } = await client.mutate({
        mutation: DECLINE_SERVICE,
        variables: { packageDetailsId: cart.packageDetailsId },
      });

      if (data?.declineService) {
        setPackageOrderWithDetails((prev: any) => {
          return {
            ...prev,
            orderDetails: prev.orderDetails.map((item: any) => {
              return item.packageDetailsId === cart.packageDetailsId
                ? {
                    ...item,
                    cartDetails: {
                      ...item.cartDetails,
                      isServiceDeclined: SERVICE_STATE.DECLINED,
                    },
                  }
                : item;
            }),
          };
        });
        successMsg("Service Declined");
      }
    }
  };
  
  const cardIcon = cardGroupIcons[cart.group] || defaultIcon;
  
  useEffect(() => {
    generateMailtoLink(client, window.location.pathname, setEmailLink);
  }, [client]);

  return (
    <StagedCardLayout
      cardIcon={cardIcon}
      cardTitle={cardTitle}
      datetime={cardDate ? cardDate : null}
      time={cardTime ? cardTime : null}
      isPublished={isPublished}
      isServiceDeclined={isServiceDeclined}
      serviceDeclined={serviceDeclined}
      isMissingInfo={isMissingInfo} 
    >
      <div className="card-container">
        <Row className="d-flex flex-column flex-nowrap">
          <Col
            sm={
              cart.type && EDITABLE_CART_TYPES.includes(cart.type) && cart.cartDetails ? "10" : "12"
            }
            className="ml-4 d-flex align-items-center flex-wrap"
          >
            {/* <h1 className="m-0 pr-3">
              {cardTitle}
              {cardDate ? (
                <>
                  &nbsp; — &nbsp;
                  {moment(cardDate).format("h:mm a").includes('3:30 am') ? moment(cardDate).format("h:mm a").replace('3:30 am', 'Time TBD') : moment(cardDate).format("h:mm a")}
                </>
              ) : null}
            </h1> */}
            {cart.isCardHasPendingInfo && !isInvoiceArchived ? <PendingLabel /> : null}
            {/* {cart.cartDetails?.isServiceDeclined &&
            cart.cartDetails?.isServiceDeclined === SERVICE_STATE.DECLINED ? (
              <DeclinedLabel />
            ) : null} */}
            {/* {isShowDeclineServiceButton ? (
              <CardDeclineBtn visible={isServiceDeclined} handleClick={handleDeclineClick} />
            ) : null} */}
          </Col>
          {isShowMissingInfoLabel ? (
            <Col>
              <div>
                {/* <MissingInfo
                  isShowExclamationIcon={true}
                  isShowNote={true}
                  isClickable={cart?.cartDetails.isPublished}
                  parsedCardData={parsedCardData}
                  setModalType={setModalType}
                  setPackageDetailsId={setPackageDetailsId}
                  setIsModalOpened={setIsModalOpened}
                  setAirportTransferType={setAirportTransferType}
                  setVegasLimoTransferType={setVegasLimoTransferType}
                  cart={cart}
                /> */}
              </div>
            </Col>
          ) : null}
        </Row>
        <CardBody className="p-0 pb-2">
          <RenderCardBody
            cart={cart}
            parsedCardData={parsedCardData}
            airportTransferType={airportTransferType}
            setAirportTransferType={setAirportTransferType}
            vegasLimoTransferType={vegasLimoTransferType}
            setVegasLimoTransferType={setVegasLimoTransferType}
            isCartInfoOpenedId={isCartInfoOpenedId}
            handleOpenDetails={handleOpenDetails}
            isInvoiceArchived={isInvoiceArchived}
            handleOpenLearnMore={handleOpenLearnMore}
            isLearnMoreOpenedId={isLearnMoreOpenedId}
            isPublished={isPublished}
          >
            <>
              {isShowDeclineServiceButton ? (
                <div style={{ marginTop: "20px" }}>
                  <CardDeclineBtn visible={isServiceDeclined} handleClick={handleDeclineClick} />
                </div>
              ) : null}
              
              <div className="divider" />

              {!isPublished && 
                <div style={{ marginTop: "20px" }}>
                  <CardAction icon={helpIcon} text="Get help" handleClick={() => window.location.href = emailLink} />
                </div>
              }
            </>
          </RenderCardBody>
        </CardBody>
      </div>
    </StagedCardLayout>
  );
};

export default PackageDetailsCard;
