import React from "react";
import { useLazyQuery } from "@apollo/client";
import { Modal, Spinner } from "reactstrap";
import { GET_TICKETS_LIST_FOR_TICKETS_MODAL } from "../../queries/vip-workflow";
import { Document, Page, pdfjs } from "react-pdf";
import UploadedTickets from "./UploadedTickets";
import UploadedUrls from "./UploadedUrls";
import ticketsIcon from "../../assets/img/icons/cards/tickets-icon.svg";
import partyIcon from "../../assets/img/icons/cards/party-icon.svg";

import "react-pdf/dist/esm/Page/TextLayer.css";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "./editPackageDetailsModal.scss";
import CardGuaranteed from "../StagedCard/CardGuaranteed/CardGuaranteed";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const TicketsModal = ({
  isModalOpened,
  setIsModalOpened,
  isShowEventTicketsAdditionalMessage,
  invoiceId,
  ticketDescription,
  packageOrderWithDetails,
}: any) => {
  const [pdf, setPdf] = React.useState("");
  const [numPages, setNumPages] = React.useState<null | number>(null);
  const [pdfScale, setPdfScale] = React.useState(1.25);

  const [getTickets, { data: ticketsData, loading: ticketsLoading }] = useLazyQuery(GET_TICKETS_LIST_FOR_TICKETS_MODAL, {
    fetchPolicy: "network-only",
  });

  React.useEffect(() => {
    if (isModalOpened) {
      getTickets({ variables: { invoiceId } });
    }
  }, [isModalOpened]);

  const modalWidth = document?.getElementById("ticketsModal")?.offsetWidth;

  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
    setNumPages(numPages);
  };

  React.useEffect(() => {
    if (modalWidth && modalWidth < 800 && pdfScale !== 0.75) {
      setPdfScale(0.75);
    }

    if (modalWidth && modalWidth >= 800 && pdfScale !== 1.25) {
      setPdfScale(1.25);
    }

    if (modalWidth && modalWidth < 400 && pdfScale !== 0.59) {
      setPdfScale(0.59);
    }
  }, [modalWidth]);

  const renderTicketsForCard = (orderDetail: any) => {
    if (!orderDetail.cartDetails.isPublished) {
      return null;
    }

    const ticketsList = ticketsData?.getTicketsListForTicketsModalForClient;
    if (!ticketsList) return null;

    const ticketEntries = Object.entries(JSON.parse(ticketsList || "{}"));
    const ticketsForCard = ticketEntries.filter(([key, value]: any) =>
      value.some((ticket: any) => ticket.packageDetailsId === orderDetail.cartDetails.packageDetailsId)
    );

    if (ticketsForCard.length === 0) {
      return null;
    }

    return ticketsForCard.map(([key, value]: any) => {
      const ticketsForUploadedTickets = value.filter((ticket: any) => !ticket?.eTicketLink?.length);
      const ticketsForUploadedUrls = value.filter((ticket: any) => ticket?.eTicketLink?.length);

      if (ticketsForUploadedTickets.length === 0 && ticketsForUploadedUrls.length === 0) {
        return null;
      }

      return (
        <React.Fragment key={key}>
          <div>
            <h2 className="text-capitalize">
              {key === "Event Details" && !isShowEventTicketsAdditionalMessage ? "Event Tickets" : key}
            </h2>
          </div>
          <div>
            {ticketsForUploadedTickets.filter((ticket: any) => ticket.key).map((ticket: any, index: number) => (
              <UploadedTickets key={index} ticket={ticket} index={index} setPdf={setPdf} />
            ))}
            {ticketsForUploadedUrls.map((ticket: any, index: number) => (
              <UploadedUrls key={index} ticket={ticket} index={index} />
            ))}
          </div>
          <div className="divider"></div>
        </React.Fragment>
      );
    });
  };

  const renderCards = () => {
    if (!packageOrderWithDetails) return null;

    const publishedCards = packageOrderWithDetails.orderDetails.filter((orderDetail: any) => orderDetail.cartDetails.isPublished);
    const cardsWithTickets = publishedCards.filter((orderDetail: any) => {
      const ticketsList = ticketsData?.getTicketsListForTicketsModalForClient;
      if (!ticketsList) return false;

      const ticketEntries = Object.entries(JSON.parse(ticketsList || "{}"));
      const ticketsForCard = ticketEntries.find(([key, value]: any) =>
        value.some((ticket: any) => ticket.packageDetailsId === orderDetail.cartDetails.packageDetailsId)
      );

      return ticketsForCard ? true : false;
    });

    if (publishedCards?.length === 0) {
      return (
        <CardGuaranteed text={`Your package includes ${ticketDescription.toLowerCase()}. We will let you know as soon as they are confirmed.`} subtext="Details on how to access your tickets will be available as the event approaches." />
      );
    }

    if (cardsWithTickets?.length === 0) {
      return (
        <CardGuaranteed icon={partyIcon} text={`Your tickets for ${ticketDescription.toLowerCase()} have been secured.`} subtext="Details on how to access your tickets will be available as the event approaches." />
      );
    }

    return cardsWithTickets.map((orderDetail: any) => {
      const ticketsRendered = renderTicketsForCard(orderDetail);

      return (
        <div key={orderDetail.cartDetails.packageDetailsId}>
          {ticketsRendered}
        </div>
      );
    });
  };

  return (
    <Modal
      isOpen={isModalOpened}
      toggle={() => {
        setIsModalOpened(false);
        setPdf("");
      }}
      contentClassName="border-radius bg-white"
      className="modal-dialog-centered modal-secondary"
      size="lg"
      fade={false}
      id="ticketsModal"
    >
      <div className="modal-body p-0">
        <div className="d-flex justify-content-between p-4 border-bottom">
          <div className="d-flex align-items-center">
            <img className="mr-2" src={ticketsIcon} alt="tickets-icon" />
            <span className="tickets-details-span">Ticket Details</span>
          </div>
          <div className="d-flex align-items-center">
            <button
              style={{ background: "none", border: "none" }}
              onClick={(e) => {
                e.preventDefault();
                setIsModalOpened(false);
                setPdf("");
              }}
            >
              <i className="fas fa-times" aria-hidden="true" />
            </button>
          </div>
        </div>
        {pdf ? (
          <div className="px-4 py-2">
            <button className="btn btn-md p-1" onClick={() => { setPdf(""); }}>
              &larr; Back To Tickets List
            </button>
            <div className="mt-2">
              <Document file={pdf} onLoadSuccess={onDocumentLoadSuccess}>
                {Array.from(new Array(numPages), (el, index) => (
                  <Page key={`page_${index + 1}`} pageNumber={index + 1} scale={pdfScale} />
                ))}
              </Document>
            </div>
          </div>
        ) : ticketsLoading ? (
          <div className="d-flex justify-content-center p-4">
            <Spinner animation="border" variant="info" size="lg" />
          </div>
        ) : (
          <div className="px-4 py-2">
            {isShowEventTicketsAdditionalMessage ? (
              <>
                <div className="mt-3">
                  <div>
                    <h2 className="text-capitalize">Event Tickets</h2>
                  </div>
                  <div>
                    <div className="d-flex align-items-center mt-2 justify-content-between">
                      <div style={{ width: "100%" }}>
                        <i className="fa fa-thin fa-paperclip mr-2"></i>
                        <span style={{ fontStyle: "italic" }}>
                          You will receive an email or notification in your app once your Event
                          Tickets will be available. They may not be accessible here.
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="divider"></div>
              </>
            ) : null}
            {renderCards()}
          </div>
        )}
      </div>
    </Modal>
  );
};

export default TicketsModal;
